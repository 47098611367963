/**
 * @module
 */
import Searcher from './Searcher.js'
import ResultType from "../ResultType.js"
import * as reproject from "../util/reproject.js"

const BASE_URL = 'https://dawa.aws.dk/stednavne2'
const GET_URL = 'https://dawa.aws.dk/stednavne'

/**
 * 
 * Searches Dawa stednavne endpoint
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration: <a href='../examples/builder/'>Example</a></caption>
 _type: Septima.Search.DawaStednavnSearcher
 _options:
   kommunekode: 151
 *
 * @example <caption> JS options: <a href='../examples/simple.dk/'>Example</a></caption>
 * options = {
 *        types: [{
 *           name: 'Bebyggelse',
 *           hovedtype: 'Bebyggelse|Bygning'
 *         },{
 *           name: 'Motor og motocrossbane',
 *           undertype: 'motorbane|motocrossbane',
 *           iconuri: 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMzc5LjgyODEyNSAwaC0yMTcuNjU2MjVjLTcyLjg3NSAwLTEzMi4xNzE4NzUgNTkuMjk2ODc1LTEzMi4xNzE4NzUgMTMyLjE3MTg3NXY3OC44MjgxMjVoLTMwdjMwaDEyMHYtMzBoLTMwdi03OC44MjgxMjVjMC0zOS44MDA3ODEgMzIuMzcxMDk0LTcyLjE3MTg3NSA3Mi4xNzE4NzUtNzIuMTcxODc1aDIxNy42NTYyNWMzOS44MDA3ODEgMCA3Mi4xNzE4NzUgMzIuMzcxMDk0IDcyLjE3MTg3NSA3Mi4xNzE4NzV2NjFjMCAzMS4xMTMyODEtMTkuODM1OTM4IDU4LjYyNS00OS4zNTE1NjIgNjguNDY4NzVsLTYwLjc4OTA2MyAyMC4yNTc4MTNjLTQxLjc3NzM0NCAxMy45NDUzMTItNjkuODU5Mzc1IDUzLjg5NDUzMS02OS44NTkzNzUgOTcuOTI5Njg3IDAgMzkuODAwNzgxLTMzLjM3MTA5NCA3Mi4xNzE4NzUtNzMuMTcxODc1IDcyLjE3MTg3NWgtMzYuNjU2MjVjLTM5LjgwMDc4MSAwLTcyLjE3MTg3NS0zMi4zNzEwOTQtNzIuMTcxODc1LTcyLjE3MTg3NXYtNzguODI4MTI1aDMwdi0zMGgtMTIwdjMwaDMwdjc4LjgyODEyNWMwIDcyLjg3NSA1OS4yOTY4NzUgMTMyLjE3MTg3NSAxMzIuMTcxODc1IDEzMi4xNzE4NzVoMzYuNjU2MjVjNzIuODc1IDAgMTMzLjE3MTg3NS01OS4yOTY4NzUgMTMzLjE3MTg3NS0xMzIuMTcxODc1IDAtMTguMTc5Njg3IDExLjU4NTkzOC0zNS4yNSAyOC44NDM3NS00MS4wMDc4MTNsNjAuNzg5MDYyLTIwLjI2OTUzMWM1NC4wNTQ2ODgtMTguMDE5NTMxIDkwLjM2NzE4OC02OC40MTAxNTYgOTAuMzY3MTg4LTEyNS4zNzg5MDZ2LTYxYzAtNzIuODc1LTU5LjI5Njg3NS0xMzIuMTcxODc1LTEzMi4xNzE4NzUtMTMyLjE3MTg3NXptMCAwIi8+PC9zdmc+'
 *         }]
 *       }
 * @example <caption>js client:</caption>
 * <!-- Include septimaSearch -->
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * searchers.push(new Septima.Search.DawaStednavnSearcher(options))
 * 
 * @example <caption>ES6:</caption>
 * import DawaStednavnSearcher from './searchers/DawaStednavnSearcher.js'
 * controller.addSearcher(new DawaStednavnSearcher(options))
 * @api
 */
export default class DawaStednavnSearcher extends Searcher {
  /**
   *
   * @param {Object} options
   * @param {String} [options.kommunekode=*] "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {String} [options.visUOfficiel=false] Search and show unofficial names of places
   * @param {Object[]} [options.types] User defined type - one of either hoved- or undertype MUST be given
   * @param {String} options.types[].name The name of the user defined type as it will be shown in result list
   * @param {String} [options.types[].hovedtype] One or more of hovedtype or
   * @param {String} [options.types[].undertype] One or more of undertype 
   * @param {String} [options.types[].iconuri]
   *
   */
  constructor(options) {
    super(options)
    this.options = options
    this.source = "Dawa"
    this.myTypes = []
    if (options.types) {
      for (let type of options.types) {
        let id = type.id ? type.id : type.name
        let type2Register = new ResultType({
          id: id,
          singular: type.singular ? type.singular : id,
          plural: type.plural ? type.plural : id
        })
        type2Register.hovedtype = type.hovedtype
        type2Register.undertype = type.undertype
        this.myTypes.push(type2Register)
        this.registerType(this.source, type2Register)
      }
    } else {
      let type2Register = new ResultType({
        id: 'stednavn',
        singular: 'Stednavn',
        plural: 'Stednavne'
      })
      this.myTypes.push(type2Register)
      this.registerType(this.source, type2Register)
    }
    
    this.kommunekode = null
    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += ''
      let kommunekoder = options.kommunekode.split(' ')
      this.kommunekode = kommunekoder.join('|')
    }

    this.visUOfficiel = false
    if (options.visUOfficiel) 
      this.visUOfficiel = options.visUOfficiel

    reproject.registerCrs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")
    
  }

  async fetchData(query, caller) {
    caller.fetchSuccess(await this.query(query))
  }

  async query(query) {
    let queryResult = this.createQueryResult()
    if (query.queryString.length < 80) { 
      let typesToSearch = this.myTypes
      if (query.hasTarget)
        typesToSearch = [this.myTypes.find(t => t.id === query.target.type)]

      for (let type of typesToSearch) {
        let title = type.plural
        let items = await this.fetchItems(query, type, Math.max(query.limit, 100) + 1)
        let count = items.length
        if (["list", "cut", "no-cut"].indexOf(query.type) !== -1) {
          let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
          for (let item of items.slice(0, hitsShown))
            this.addItemAsResult(queryResult, type, item)

          if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
            if (count > Math.max(query.limit, 100))
              title += ` (${Math.max(query.limit, 100)}+)`
            else
              title += ` (${count})`

            let newQuery = queryResult.addNewQuery(this.source, type.id, title, null, query.queryString, null, null)
            if (typeof type.iconuri !== 'undefined')
              newQuery.image = type.iconuri
            else if (typeof this.options.iconuri !== 'undefined')
              newQuery.image = this.options.iconuri
          }
        } else {
          if (count > 0) {
            let newQuery = queryResult.addNewQuery(this.source, type.id, title, null, query.queryString, null, null)
            if (typeof type.iconuri !== 'undefined')
              newQuery.image = type.iconuri
            else if (typeof this.options.iconuri !== 'undefined')
              newQuery.image = this.options.iconuri
          }
        }
      }
    }
    return queryResult
  }

  async fetchItems(query, type, limit) {
    limit = limit || query.limit
    let typeCriteria
    if (typeof type.hovedtype !== 'undefined') 
      typeCriteria = `hovedtype=${type.hovedtype}`
    else 
      typeCriteria = `undertype=${type.undertype}`
    
    //https://dawa.aws.dk/dok/api/generelt#tekstsoegning
    let params = {}
    if (this.kommunekode != null) 
      params.kommunekode = this.kommunekode
    
    let items
    if (query.queryString.length > 0) {
      let queryParam = query.queryString + '*'
      items = await this.fetch(`${BASE_URL}/?q=${queryParam}&${typeCriteria}&per_side=${limit}`, {data: params})
    } else {
      //Ask for plus 100 for something to sort on
      items = await this.fetch(`${BASE_URL}/?${typeCriteria}&per_side=${limit + 100}`, {data: params} )
      items.sort((item1, item2) => item1.navn.localeCompare(item2.navn))
      items = items.slice(0, limit)
    }

    let filteredItems = this.removeDuplicates(items)
    return filteredItems
  }

  removeDuplicates(items) {
    //https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
    return items.filter((item, pos, arr) => {
      return arr.map(mapObj => mapObj.sted.id).indexOf(item.sted.id) === pos
    })
  }

  addItemAsResult(results, type, item) {
    let {navn, navnestatus, sted} = item
    let itemId = sted.id
    // Enrich name with alternatives
    if (navnestatus === 'officielt' || navnestatus === 'suAutoriseret') {
      if (sted["primærtnavn"].length > 0 && sted["primærnavnestatus"] === 'uofficielt') 
        navn += ' (' + sted["primærtnavn"] + ')'
      else if (this.visUOfficiel && sted["sekundærenavne"].length > 0 && sted["sekundærenavne"][0].navnestatus === 'uofficielt') 
        navn += ' (' + sted["sekundærenavne"][0].navn + ')'
      
    } else if (navnestatus === 'uofficielt') {
      if (sted["primærtnavn"].length > 0 && sted["primærnavnestatus"] === 'officielt') 
        navn += ' (' + sted["primærtnavn"] + ')'
      else if (sted["sekundærenavne"].length > 0 && sted["sekundærenavne"][0].navnestatus === 'officielt') 
        navn += ' (' + sted["sekundærenavne"][0].navn + ')'
      
    }
    //Create a description
    
    let description = null
    if (typeof sted.kommuner !== 'undefined')
      description = sted.kommuner[0].navn
    
    if (typeof type.hovedtype !== 'undefined' && type.hovedtype.indexOf('|') > -1) {
      if (type.hovedtype.indexOf('|') > -1) 
        description = sted.hovedtype + (description ? ', ' + description : '')
    } else {
      if (type.undertype.indexOf('|') > -1) 
        description = this.capitalizeFirstLetter(sted.undertype) +  (description ? ', ' + description : '')
      
    }
    let result = results.addResult(this.source, type.id, navn, description, null, item)
    result.id = itemId
    result.isComplete = false
    if (typeof type.iconuri !== 'undefined') 
      result.image = type.iconuri
    else if (typeof this.options.iconuri !== 'undefined') 
      result.image = this.options.iconuri
    
  }

  capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1)
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      result.isComplete = true
      let feature = await this.get(result.id, result.typeId)
      result.geometry = feature.geometry
      return result
    }
  }

  async get(id, typeId) {
    //let type = this.options.types.find(type => type.name === typeName)
    let feature = await this.fetch(`${GET_URL}/${id}?format=geojson&srid=25832`)
    let results = this.createQueryResult()
    let result = this.addFeatureAsResult(results, typeId, feature)
    return result
  }

  addFeatureAsResult(results, typeId, feature) {
    let {geometry, properties} = feature
    let result = results.addResult(this.source, typeId, properties.navn, null, geometry, feature)
    result.id = properties.id
    return result
  }
}