/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import * as reproject from "../../util/reproject.js"

export default class VirksomhederMedBranchekodeRadiusforAdresse extends DetailsHandlerDef {
  /**
     * @param {Object} options
     * @param {Object} options.cvr_enhedSearcher An instance of a Septima.Search.CVR_enhedSearcher
     * @param {Object} options.codes An array of cvr codes
     * @param {object} options.distance Radius
     **/
  constructor(options = {}) {
    if (!options.cvr_enhedSearcher)
      throw "VirksomhederMedBranchekodeRadiusforAdresse needs a CVR_enhedSearcher"
    if (!options.codes)
      throw "Need codes in options"
    
    let defaultOptions = {
      buttonText: "Virksomheder i nærheden af husnummeret",
      buttonImage: options.cvr_enhedSearcher.iconURI,
      distance: "100m"
    }
    let finalOptions = Object.assign(defaultOptions, options)
    super(finalOptions)
   
    //this.distance = "500m" //https://www.elastic.co/guide/en/elasticsearch/reference/current/common-options.html#distance-units
    
    this.listHeader = this.buttonText
    if (options.displayName)
      this.listHeader = options.displayName
    
    this.isApplicableFunction = this.isApplicable
    this.distance = options.distance
    this.codes = options.codes
    this.handlerFunction = this.myHandler
    this.cvr_enhedSearcher = options.cvr_enhedSearcher
  }

  isApplicable(result) {
    return (result.source === "Dawa" && result.typeId === "adresse")
  }

  async myHandler(result) {
    let reprojected = this.convert2582GeometryTowgs84LatLon(result.geometry)
    let filter = this.createFilter(this.codes, this.distance, reprojected.lon, reprojected.lat)

    let url = 'https://new-searchindex.septima.dk/septimaSEARCHDEMO-A7OLGHG2J4/cvr/produktionsenheder/_search?size=100&source='+filter
    const virksomheder = (await(await fetch(url, {
      method: 'GET',
      mode: 'cors'
    })).json())

    let pnrs = virksomheder.hits.hits.map(h => h._id)
    let pnrPromises = pnrs.map(p => this.cvr_enhedSearcher.get(p)) 
    let featuresArray = await Promise.all(pnrPromises)
    let detailItemsList
    if (featuresArray.length > 0) {
      detailItemsList = new DetailItemsList({
        itemType: "result",
        header: this.more ? this.buttonText : this.listHeader,
        image: this.buttonImage,
        name: "virksomheder_liste"
      })
      for (let f of featuresArray)
        detailItemsList.append({
          type: "result",
          result: f
        })
    } else {
      detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        name: "virksomheder_ingen"
      })
      detailItemsList.append({
        type: "labelvalue",
        value: "Ingen virksomheder fundet i nærheden"
      })
    }
    return [detailItemsList.asItem()]
  }

  convert2582GeometryTowgs84LatLon(geometry) {
    let crss = {
      "EPSG:25832": "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
      "EPSG:4326": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"
    }
    let geoJsonWgs84 = reproject.reproject(geometry, "EPSG:25832", "EPSG:4326", crss)
    return { lon: geoJsonWgs84.coordinates[0], lat: geoJsonWgs84.coordinates[1] }
  }
  
  createFilter(codes, distance, lon, lat) {
    let jsonfilter = {
      "query":{
        "terms":{
          "hovedbranche_kode":[ ]
        }
      },
      "filter":{
        "geo_distance":{
          "distance":"1km",
          "septima_geometri":[]
        }
      }
    }
    jsonfilter.query.terms.hovedbranche_kode=codes
    jsonfilter.filter.geo_distance.distance=distance
    jsonfilter.filter.geo_distance.septima_geometri = [lon,lat]
    return JSON.stringify(jsonfilter)
  }
}