/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import { fetch2 } from '../../utils.js'

/**
 * Viser link til datacvr.virk.dk for cvr.produktionsenhed resultater
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.CVR_enhedSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.RegnskaberForCvrNummerHandler
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.RegnskaberForCvrNummerHandler({more: false}))
 *
 * @example <caption>ES6:</caption>
 * import CvrLinkHandler from './searchers/detailhandlers/CvrLinkHandler.js'
 * dawaSearcher.addDetailHandlerDef(new RegnskaberForCvrNummerHandler({more: false}))
 * @api
 **/
export default class RegnskaberForCvrNummerHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "RegnskaberButton",
      buttonImage: icons.searchers.cvr,
      more: false,
      endPoint: "https://regnskaber.k8s-test-121.septima.dk"
    }
    let finalOptions = Object.assign(defaultOptions, options) 
    super(finalOptions)
    this.options = finalOptions
    
    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (result.source === "cvr" && result.typeId === "produktionsenhed")
    }
  }
  async myHandler(result) {
    let cvrNummer = result.data.fields.virksomhed_cvrnr[0]
    try {
      let regnskaber = await fetch2(`${this.options.endPoint}/Regnskaber?Cvrnr=${cvrNummer}&regnskabsantal=1000`)
      if (regnskaber.perioder)
        return [this.getRegnskaberAsList(regnskaber.perioder)]
      // eslint-disable-next-line no-empty
    } catch { }
    return [{
      type: "labelvalue",
      value: "Ingen regnskaber"
    }]
    
  }
  getRegnskaberAsList(perioder) {
    let periodeListe = new DetailItemsList({
      itemType: "labelvalue",
      header: "Regnskaber",
      buttonText: "Regnskaber",
      infoItemsHeaders: [
        { type: "labelvalue", label: "Start" },
        { type: "labelvalue", label: "Slut" },
        { type: "labelvalue", label: "Bruttofortjeneste" },
        { type: "labelvalue", label: "Resultat af ordinær drift" },
        { type: "labelvalue", label: "Ordinært resultat før skat" },
        { type: "labelvalue", label: "Årets resultat" },
        { type: "labelvalue", label: "Egenkapital" },
        { type: "list", label: "Dokumenter" }
      ]
    })
    
    for (let periode of perioder) {
      
      let pdfLinkListe = new DetailItemsList({ itemType: "link", header: "Dokumenter" })

      let regnskabsPDFer = periode.regskabsPDFer
      if (regnskabsPDFer && regnskabsPDFer.length > 0) {
        if (regnskabsPDFer.length > 1) {
          for (let i = 0; i < regnskabsPDFer.length; i++) {
            pdfLinkListe.append({
              type: 'link',
              link: regnskabsPDFer[i],
              linkTitle: "Se regnskabsdokument #" + (i + 1)
            })
          }
        } else {
          pdfLinkListe.append({
            type: 'link',
            link: periode.regskabsPDFer[0],
            linkTitle: 'Se regnskab'
          })
        }
      }

      let bruttofortjeneste = periode.regnskabstal.find(object => {
        return object.navn === "Bruttofortjeneste"
      })
      let resultat_af_ordinaer_drift = periode.regnskabstal.find(object => {
        return object.navn === "Resultat af ordinær drift"
      })
      let ordinaert_resultat_foer_skat = periode.regnskabstal.find(object => {
        return object.navn === "Ordinært resultat før skat"
      })
      let aarets_resultat = periode.regnskabstal.find(object => {
        return object.navn === "Årets resultat"
      })
      let egenkapital = periode.regnskabstal.find(object => {
        return object.navn === "Egenkapital"
      })

      periodeListe.append({
        type: "labelvalue",
        value: periode.start.substring(0, 4),
        infoItems: [
          { type: "labelvalue", valueformat: "iso-date", value: periode.start },
          { type: "labelvalue", valueformat: "iso-date", value: periode.slut },
          { type: "labelvalue", valueformat: "currencyamount", valueunit: "DKK", value: bruttofortjeneste ? bruttofortjeneste.vaerdi : null },
          { type: "labelvalue", valueformat: "currencyamount", valueunit: "DKK", value: resultat_af_ordinaer_drift ? resultat_af_ordinaer_drift.vaerdi : null },
          { type: "labelvalue", valueformat: "currencyamount", valueunit: "DKK", value: ordinaert_resultat_foer_skat ? ordinaert_resultat_foer_skat.vaerdi : null },
          { type: "labelvalue", valueformat: "currencyamount", valueunit: "DKK", value: aarets_resultat ? aarets_resultat.vaerdi : null },
          { type: "labelvalue", valueformat: "currencyamount", valueunit: "DKK", value: egenkapital ? egenkapital.vaerdi : null },
          pdfLinkListe.asItem()
        ]
      })
    }

    return periodeListe.asItem()
  }

}
