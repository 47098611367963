import icons from '../resources/icons.js'
import ResultRenderer from './ResultRenderer.js'
import domutil from '../../../lib/domutil.js'


export default class {

  constructor() {
    this.resultActionListeners = null
    this.dateTimeFormatter = new Intl.DateTimeFormat([], {dateStyle: "medium", timeStyle: "short"})
    this.dateFormatter = new Intl.DateTimeFormat([], {dateStyle: "medium"})
    this.numberFormatter = new Intl.NumberFormat([])
    this.resultActionListeners = {}
    this.detailActionListeners = {}
  }
  
  getCurrencyFormatter(isoCurrency) {
    return new Intl.NumberFormat([], { style: 'currency', currency: isoCurrency })
  }

  setResultActionListeners(listeners) {
    this.resultActionListeners = listeners
  }

  setDetailActionListeners(listeners) {
    this.detailActionListeners = listeners
  }

  formatItems(items) {
    let list = []
    let item
    let area
    if (items.length > 0 ) {
      item = items[0]
      area = this.formatItem(item)
      if (Array.isArray(area))
        area.forEach((childRow) =>{
          list.push(childRow)
        })
      else
        list.push(area)
    }
    for (let i=1; i<items.length; i++) {
      item = items[i]
      area = this.formatItem(item)
      if (Array.isArray(area))
        area.forEach((childRow) =>{
          list.push(childRow)
        })
      else
        list.push(area)
    }
    return list
  }

  formatItem(detailItem) {
    let item2Format = detailItem
    let area
    if (this.detailActionListeners && this.detailActionListeners.onDetailItem) {
      item2Format = this.detailActionListeners.onDetailItem(detailItem)
      if (item2Format.onHover) {
        let orgHover = item2Format.onHover
        item2Format.onHover = () => {
          try {
            orgHover(detailItem)
            // eslint-disable-next-line no-empty
          } catch {}
        }
        item2Format.onHoverOut = () => {
          try {
            orgHover(null)
            // eslint-disable-next-line no-empty
          } catch {}
        }
      }
      if (item2Format.onFocus) {
        let orgFocus = item2Format.onFocus
        item2Format.onFocus = () => {
          try {
            orgFocus(detailItem)
            // eslint-disable-next-line no-empty
          } catch {}
        }
        if (!item2Format.focusIcon)
          item2Format.focusIcon = icons.mapPointGrey
      }
    }
      
    if (item2Format) {
      if (item2Format.type == "labelvalue")
        area = this.formatLabelValue(item2Format)
      else if (item2Format.type === "link")
        area = this.formatLink(item2Format)
      else if (item2Format.type === "textarea")
        area = this.formatTextArea(item2Format)
      else if (item2Format.type === "area")
        area = this.formatArea(item2Format)
      else if (item2Format.type === "button")
        area = this.formatButton(item2Format)
      else if (item2Format.type === "dom-object")
        area = item2Format.object
      else if (item2Format.type === "image")
        area = this.formatImage(item2Format)
      else if (item2Format.type === "result")
        area = this.formatResult(item2Format)
      else if (item2Format.type === "list")
        area = this.formatList(item2Format)
      else if (item2Format.type === "error")
        area = this.formatError(item2Format)

      if (area) {
        domutil.addClass(area, 'item')
        return area
      }      
    }
    return null
  }

  formatList(list) {
    
    let areas = domutil.createElement('div', 'ssDetailsGridContainer')
    if (list.header)
      areas.append( this.formatListHeader(list))
    for (let item of list.items) {
      if (item.type === "labelvalue") {
        let options = {
          type: "labelvalue",
          value: item.value,
          valueformat: item.valueformat,
          valueunit: item.valueunit
        }
        if (item.label)
          options.label = item.label
        areas.append(this.formatItem(options))
        //areas.push( this.formatArea(areaOptions))
      } else if (item.type === "result") {
        item.islistitem = true
        areas.append(this.formatResult(item))
      }else if (item.type === "link") {
        let area = this.formatLink({
          linkTitle: item.linkTitle,
          link:item.link
        })
        areas.append(area)
      }
      if (list.infoItemsHeaders) {
        for (let i = 0 ; i < list.infoItemsHeaders.length; i++) {
          let infoItemheader = list.infoItemsHeaders[i] 
          let infoItem = item.infoItems[i]
          let area = this.formatItem(Object.assign({}, infoItem, infoItemheader))
          if (area) {
            domutil.addClass(area, 'ssInfoItem')
            areas.append(area)
          }
        }
      }
    }
    return areas
  }
  
  formatError(errorItem) {
    return this.formatArea({
      icon: errorItem.icon,
      label: errorItem.label,
      value: errorItem.value
    })
  }

  formatTextArea(listItem) {
    return this.formatArea({
      icon: listItem.icon,
      label: listItem.label,
      value: listItem.text
    })
  }
  
  formatLabelValue(listItem) {
    let labelvalueArea
    if (listItem.valueformat !== 'geometry') {
      if (listItem.label) {
        labelvalueArea = domutil.createElement('div', 'ssDetailsGridContainer')
        let labelArea = this.formatArea({
          icon: listItem.icon,
          value: listItem.label + (listItem.value ? ":" : "")
        })
        domutil.addClass(labelArea, 'ssLabelLine')
        labelvalueArea.append(labelArea)
        if (listItem.value) {
          labelvalueArea.append(this.formatArea({
            value: listItem.value,
            valueformat: listItem.valueformat,
            valueunit: listItem.valueunit,
            onHover: listItem.onHover,
            onHoverOut: listItem.onHoverOut,
            onFocus: listItem.onFocus,
            focusIcon: listItem.focusIcon
          }))
        }
      } else {
        labelvalueArea = this.formatArea({
          icon: listItem.icon,
          value: listItem.value,
          valueformat: listItem.valueformat,
          valueunit: listItem.valueunit,
          onHover: listItem.onHover,
          onHoverOut: listItem.onHoverOut,
          onFocus: listItem.onFocus,
          focusIcon: listItem.focusIcon
        })
      }
    }
    return labelvalueArea
  }


  formatLink(linkItem) {
    let linkItemArea
    let clickableArea
    
    if (linkItem.label) {
      linkItemArea = domutil.createElement('div', 'ssDetailsGridContainer')
      let labelArea = this.formatArea({
        icon: linkItem.icon,
        value: linkItem.label + ":"
      })
      domutil.addClass(labelArea, 'ssLabelLine')
      linkItemArea.append(labelArea)
      clickableArea = this.formatArea({
        value: linkItem.linkTitle,
        clickable: true,
        valueformat: "link"
      }) 
      linkItemArea.append(clickableArea)
    } else {
      clickableArea = this.formatArea({
        icon: linkItem.icon,
        value: linkItem.linkTitle,
        clickable: true,
        valueformat: "link"
      })
      linkItemArea = clickableArea
    }

    clickableArea.addEventListener('click', ()=>{
      window.open(linkItem.link)
      return false
    })
    return linkItemArea
  }

  formatImage(listItem) {
    let area = domutil.createElement('div', 'ssDetailsGrid')
    if (typeof listItem.icon !== 'undefined') {
      area.append(this.createIconCol(domutil.createImageElement(listItem.icon)))
      let value = domutil.createElement('div', 'ssValue')
      value.append(this.createImageCol(listItem.value, listItem.label))
      area.append(value)
    }else{
      let value = domutil.createElement('div', 'ssValue')
      value.append(this.createImageCol(listItem.value, listItem.label))
      area.append(value)
      domutil.addClass(area, 'ssListIndent')
    }
    return area
  }
  
  formatResult(resultItem) {
    if (resultItem.label) {
      let resultItemArea = domutil.createElement('div', 'ssDetailsGridContainer')
      let labelArea = this.formatArea({
        value: resultItem.label + ":"
      })
      domutil.addClass(labelArea, 'ssLabelLine')
      resultItemArea.append(labelArea)
      resultItemArea.append(this.formatResultItemArea(resultItem))
      return resultItemArea
    } else {
      return this.formatResultItemArea(resultItem)
    }
  }
  
  formatButton_org(listItem) {
    return this.formatArea({
      icon: listItem.icon,
      value: listItem.title,
      clickable: true
    })
  }

  formatButton(listItem) {
    let buttomItemArea = domutil.createElement('div', 'ssDetailsGrid')

    //noop to fill first column
    //let iconCol = this.createIconCol()
    //buttomItemArea.append(iconCol)

    let value = domutil.createElement('div', 'ssValue')
    domutil.addClass(value, 'ssButton')
    buttomItemArea.append(value)

    value.append(this.createIconCol(listItem.icon))
    value.append(listItem.title)

    if (listItem.onClick)
      value.addEventListener('click', ()=>{
        listItem.onClick()
      })
    return buttomItemArea
    
  }

  formatResultItemArea(resultItem) {

    let result = resultItem.result
    let resultItemArea = domutil.createElement('div', 'ssDetailsGrid')

    //noop to fill first column
    let iconCol = this.createIconCol()
    resultItemArea.append(iconCol)

    let value = domutil.createElement('div', 'ssValue')
    domutil.addClass(value, 'ssResult')
    domutil.addClass(value, 'ssClickable')
    resultItemArea.append(value)
    
    if (typeof result.image !== 'undefined') {
      iconCol = this.createIconCol(domutil.createImageElement(result.image))
      value.append(iconCol)
    }
    
    let titleToDisplay = result.title.substr(0, 30)
    if (result.title.length > 30)
      titleToDisplay += "..."

    let titleElement = domutil.createElement('div', 'abekat', titleToDisplay)
    let htmlTitle = result.title
    if (result.description && result.description.length > 0)
      htmlTitle += ' - ' + result.description

    domutil.setAttribute(titleElement, 'title', htmlTitle)
    domutil.addClass(titleElement, 'ssTruncate')
    value.append(titleElement)

    if (typeof this.resultActionListeners.hover !== 'undefined') {
      domutil.addClass(value, 'ssHoverable')
      value.addEventListener("mouseenter", ()=>{
        this.resultHover(result, resultItem.infoItems)
      })
      value.addEventListener("mouseleave", ()=>{
        this.resultHover(null)
      })
    }

    if (this.resultActionListeners && this.resultActionListeners.select) {
      value.addEventListener('click', ()=>{
        this.selectResult(result)
      })
    }

    if (result.searcher)
      if (!result.searcher.hasdetailHandlerDefs(result)) {
        let customButtonDefs = result.searcher.getCustomButtonDefs(result)

        for (let customButtonDef of customButtonDefs) {
          let button = ResultRenderer.createButton(result, customButtonDef)
          let iconCol = this.createIconCol(button)
          domutil.addClass(iconCol, 'ssGhostBtn')
          resultItemArea.append(iconCol)
        }
      } else {
        result._doDetail = true
        let detailsIcon = domutil.createImageElement(icons.list.detailsIconWhite)
        let detailsIconCol = this.createIconCol(detailsIcon)
        domutil.addClass(detailsIconCol, 'ssFixedBtn')
        value.append(detailsIconCol)
      }

    if (typeof this.resultActionListeners.focus !== 'undefined') {
      let focusIcon = domutil.createImageElement(this.resultActionListeners['focusIcon'])
      focusIcon.addEventListener('click', ()=>{
        this.resultFocus(result, resultItem.infoItems)
        return false
      })
      const focusIconCol = this.createIconCol(focusIcon)
      domutil.addClass(focusIconCol, 'ssFixedBtn')
      resultItemArea.append(focusIconCol)
    }

    return resultItemArea
  }

  createImageCol(url, label) {
    let image = domutil.createImageElement(url, label ? label :"")
    return image
  }

  formatListHeader(list) {
    let area = domutil.createElement('div', 'ssDetailsGrid')
    let valueElement = domutil.createElement('div', 'ssValue', list.header)
    area.append(valueElement)
    domutil.addClass(valueElement, 'ssListHeader')
    return area
  }

  formatArea(options) {
    // Basale layout
    //options: {icon:, label, value, liveIcons, fixedIcons, truncate, hoverable, clickable, islistheader}
    let area = domutil.createElement('div', 'ssDetailsGrid')

    if (typeof options.icon !== 'undefined') {
      // TBD: Kan være el allerede
      let el = options.icon
      if (!(options.icon instanceof Element))
        el = domutil.createImageElement(options.icon)
      area.append(this.createIconCol(el))
    }

    if (options.label) {
      let label = domutil.createElement('div', 'ssLabel', options.label)
      domutil.setAttribute(label, "title", options.label)

      if (options.truncate)
        domutil.addClass(label, 'ssTruncate')

      area.append(label)
    }
    let valueElement = domutil.createElement('div', 'ssValue')
    if (options.value) {
      let value
      // TBD: Kan være el allerede
      if (options.value instanceof Element) {
        valueElement = options.value
      } else {
        if (options.valueformat) {
          if (options.valueformat === "int") {
            value = this.numberFormatter.format(options.value)
            if (options.valueunit) {
              let intValue = parseInt(options.value)
              if (options.valueunit == "m") {
                let presentationValue = this.numberFormatter.format(intValue > 999 ? (intValue / 1000).toFixed(1) : intValue)
                let presentationUnit = intValue > 999 ? 'km' : 'm'
                value = presentationValue + " " + presentationUnit
              }
              if (options.valueunit == "s") {
                let t = this.secondsToTime(intValue)
                value = ""

                if (t.h === 0) {
                  if (t.m > 0)
                    value = t.m + ' min'
                  else
                    value = intValue + ' sek'
                } else if (t.h === 1)
                  value = ' 1 time ' + t.m + ' min'
                else if (t.h > 1)
                  value = t.h + ' timer ' + t.m + ' min'
              }
            }
            valueElement = domutil.createElementFromHtmlString("<div class='ssValue ssNumber'>" + value + "</div>")
          }
          if (options.valueformat === "number") {
            value = this.numberFormatter.format(options.value)
            valueElement = domutil.createElementFromHtmlString("<div class='ssValue ssNumber'>" + value + "</div>")
          }
          if (options.valueformat === "iso-date") {
            let date = new Date(options.value)
            value = this.dateFormatter.format(date)
            valueElement = domutil.createElementFromHtmlString("<div class='ssValue ssDate'>" + value + "</div>")
          }
          if (options.valueformat === "iso-datetime") {
            let date = new Date(options.value)
            value = this.dateTimeFormatter.format(date)
            valueElement = domutil.createElementFromHtmlString("<div class='ssValue ssDate'>" + value + "</div>")
          }
          if (options.valueformat === "currencyamount") {
            let formatter = this.numberFormatter
            if (options.valueunit)
              formatter = this.getCurrencyFormatter(options.valueunit)
            try {
              value = formatter.format(options.value)
            } catch {
              value = this.numberFormatter.format(options.value)
            }
            valueElement = domutil.createElementFromHtmlString("<div class='ssValue ssDate'>" + value + "</div>")
          }
          if (options.valueformat === "iso-year") {
            value = options.value.substring(0, 4)
            valueElement = domutil.createElementFromHtmlString("<div class='ssValue ssDate'>" + value + "</div>")
          }
          if (options.valueformat === "pre")
            valueElement = domutil.createElementFromHtmlString("<div class='ssValue'><pre>" + options.value + "</pre></div>")
          if (options.valueformat === "link") {
            value = options.value
            valueElement = domutil.createElementFromHtmlString("<div class='ssValue ssLink'>" + value + "</div>")
          }
        } else {
          value = options.value
          valueElement = domutil.createElementFromHtmlString("<div class='ssValue'>" + value + "</div>")
        }
        domutil.setAttribute(valueElement, "title", value)
      }

      if (options.truncate)
        domutil.addClass(valueElement, 'ssTruncate')

    }

    area.append(valueElement)

    if (options.liveIcons)
      for (let liveIcon of options.liveIcons) {
        let liveIconCol = this.createIconCol(domutil.createImageElement(liveIcon))
        domutil.addClass(liveIconCol, 'ssGhostBtn')
        area.append(liveIconCol)
      }


    let detailsIconCol = null
    if (options.clickable)
      domutil.addClass(area, "ssClickable")

    if (options.fixedIcons)
      for (let fixedIcon of options.fixedIcons) {
        let fixedIconCol = this.createIconCol(domutil.createImageElement(fixedIcon))
        domutil.addClass(fixedIconCol, "ssFixedBtn")
        area.append(fixedIconCol)
      }

    
    if (options.onFocus || options.onHover) {
      let focusIcon = domutil.createImageElement(options.focusIcon)
      const focusIconCol = this.createIconCol(focusIcon)
      domutil.addClass(focusIconCol, 'ssFixedBtn')
      area.append(focusIconCol)
      
      if (options.onFocus) {
        focusIcon.addEventListener('click', () => {
          if (options.onHoverOut)
            options.onHoverOut()
          options.onFocus()
          return false
        })
      }

      if (options.onHover) {
        domutil.addClass(valueElement, 'ssHoverable')
        focusIcon.addEventListener("mouseenter", () => {
          options.onHover()
        })
        if (options.onHoverOut) {
          focusIcon.addEventListener("mouseleave", () => {
            options.onHoverOut()
          })
        }
      }
      
    }
    
    if (detailsIconCol !== null)
      area.append(detailsIconCol)

    if (typeof options.icon === 'undefined')
      domutil.addClass(area, "ssListIndent")

    if (options.islistheader)
      domutil.addClass(area, "ssHeader")

    return area
  }

  createIconCol(icon) {
    let iconCol = domutil.createElement('div', 'ssIcon')
    if (typeof icon !== 'undefined') {
      iconCol.append(icon)
    }
    return iconCol
  }

  selectResult(result) {
    if (this.resultActionListeners) {
      if (this.resultActionListeners.hover)
        this.resultActionListeners.hover(null)
      if (this.resultActionListeners.select)
        this.resultActionListeners.select(result)
    }
  }

  resultHover(result, infoItems) {
    if (this.resultActionListeners)
      this.resultActionListeners.hover(result, infoItems)
  }

  resultFocus(result) {
    if (this.resultActionListeners) {
      if (this.resultActionListeners.hover)
        this.resultActionListeners.hover(null)

      if (this.resultActionListeners.focus)
        this.resultActionListeners.focus(result)

    }
  }

  secondsToTime(secs) {
    if (secs < 0)
      secs += 24 * 60 * 60


    const hours = (Math.floor(secs / (60 * 60))) % 24

    const divisor_for_minutes = secs % (60 * 60)
    const minutes = Math.floor(divisor_for_minutes / 60)

    const divisor_for_seconds = divisor_for_minutes % 60
    const seconds = Math.ceil(divisor_for_seconds)

    return {
      "h": hours,
      "m": minutes,
      "s": seconds
    }
  }

}
