/**
 * @module
 **/
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import {getWKTParser} from "../../util/getWKTParser.js"
/**
 * Viser info for et matrikelnummer fra GeoSearch
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.GeoSearch
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.JordStykkeInfoProvider
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 *geoSearcher.addDetailHandlerDef(new Septima.Search.JordStykkeInfoProvider())
 *
 * @example <caption>ES6:</caption>
 * import JordStykkeInfoProvider from './searchers/detailhandlers/JordStykkeInfoProvider.js'
 * geoSearcher.addDetailHandlerDef(new JordStykkeInfoProvider())
 * @api
 **/
export default class JordStykkeInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields=['kommune_result','postdistrikt_result'] Felter, der skal medtages i info. Mulige felter er "presentationString","elavsnavn", "elavskode", "sfe_nummer", 'matrnr', 'centroid_x', 'centroid_y', 'retskreds_result','opstillingskreds_result','politikreds_result','kommune_result','postdistrikt_result'
   
   **/
  constructor(options={}) {
    let defaultOptions = {
      buttonText: "Om jordstykket",
      isApplicable: (result) => {
        return (result.source === "Kortforsyningen" && (result.type.id === "matrikelnumre"))
      }
    }
    super(Object.assign(defaultOptions, options))
    
    this.handlerFunction = this.handler
    //Default fields
    this.fields = ['kommune_result', 'postdistrikt_result']
    if (typeof options !== 'undefined' && typeof options.fields !== 'undefined')
      this.fields = options.fields
  }

  async handler(result) {
    let detailItemsList = new DetailItemsList({
      header: this.more ? this.getbuttonText() : null,
      name: "info"
    })
    let wktParser = getWKTParser()
    let interiorPoint = wktParser.getInteriorPoint(result.geometry)

    for (let field of this.fields) {
      let item = await this.createItem(result, interiorPoint, field)
      if (typeof item !== 'undefined')
        detailItemsList.append(item)
    }
    return [detailItemsList.asItem()]

  }
  
  async createItem(result, interiorPoint, field) {
    let props = result.data
    if (field === 'retskreds_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "retskredse"}, geometry: interiorPoint})
      let results = sqResults.getResults()
      if (results.length > 0)
        return {
          type: 'result',
          label: "Retskreds",
          result: results[0]
        }
    }
    if (field === 'opstillingskreds_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "opstillingskredse"}, geometry: interiorPoint})
      let results = sqResults.getResults()
      if (results.length > 0)
        return {
          type: 'result',
          label: "Opstillingskreds",
          result: results[0]
        }
    }
    if (field === 'politikreds_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "politikredse"}, geometry: interiorPoint})
      let results = sqResults.getResults()
      if (results.length > 0)
        return {
          type: 'result',
          label: "Politikreds",
          result: results[0]
        }
    }
    if (field === 'kommune_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "kommuner"}, geometry: interiorPoint})
      let results = sqResults.getResults()
      if (results.length > 0)
        return {
          type: 'result',
          label: "Kommune",
          result: results[0]
        }
    }
    if (field === 'postdistrikt_result') {
      let sqResults = await result.searcher.sq({target: {source: "Kortforsyningen", type: "postdistrikter"}, geometry: interiorPoint})
      let results = sqResults.getResults() 
      if (results.length > 0)
        return {
          type: 'result',
          label: "Postdistrikt",
          result: results[0]
        }
    }
    if (field === 'sfe_nummer')
      return {
        type: 'labelvalue',
        label: "SFE-nummer",
        value: props.sfeejdnr
      }
    if (field === 'elavsnavn') 
      return {
        type: 'labelvalue',
        label: 'Ejerlav',
        value: props.elavsnavn
      }
    else if(field === 'elavskode') 
      return {
        type: 'labelvalue',
        label: 'Ejerlavskode',
        value: props.elavskode
      }
    else if(field === 'matrnr') 
      return {
        type: 'labelvalue',
        label: 'Matrikelnumer',
        value: props.matrnr
      }
    else if(field === 'presentationString') 
      return {
        type: 'labelvalue',
        label: 'Matrikel',
        value: props.presentationString
      }
    else if(field === 'centroid_x') 
      return {
        type: 'labelvalue',
        label: 'Centroide x koordinat',
        value: props.centroid_x
      }
    else if(field === 'centroid_y') 
      return {
        type: 'labelvalue',
        label: 'Centroide y koordinat',
        value: props.centroid_y
      }
  }


}