import SearchableData from "./SearchableData.js"

export default class ResultStore {
  
  constructor(options = {}) {
    if (options.source)
      this._source = options.source
    else
      throw "Resultstore must have a 'store' option"
    this._searchableData = null
    this.sort = "timestamp"
    if (options.sort)
      this.sort = options.sort
    this.maxcount = 20
    if (options.maxcount)
      this.maxcount = options.maxcount

    this.sortMode = "search" // search / filter
    if (options.sortMode)
      this.sortMode = options.sortMode

    this.maxLength = 0
    if (options.maxLength)
      this.maxLength = options.maxLength
  }
  
  get source() {
    return this._source
  }

  _cut(array) {
    if (this.maxLength > 0 && array.length > 0)
      return array.slice(0, this.maxLength)
      //return array.slice(-1 * this.maxLength)
    return array
  }

  adjustData(results) {
    if (results.length > 1) {
      let _sortFunction = (result1, result2) => {
        if (this.sort == "timestamp") {
          let timestamp1 = parseInt(result1.timestamp)
          let timestamp2 = parseInt(result2.timestamp)
          return timestamp2 - timestamp1
        }
        return 0
      }

      results.sort(_sortFunction)
      return this._cut(results)
    }
    return results
  }

  async query(term, context) {
    //May be overriden
    //ResultStore uses SearchableData internally
    let hits = await this._getSearchableData().query(term, context)
    return hits.map(hit => hit.object)
  }

  // eslint-disable-next-line no-unused-vars
  async get(id, context) {
    //May be overriden
    return await this._find(id, context)
  }

  // eslint-disable-next-line no-unused-vars
  async getAll(context) {
    //Must override
    throw "ResultStore.getAll must be implemented"
  }

  // eslint-disable-next-line no-unused-vars
  async remove(id) {
    //Must be overridden
    throw "ResultStore.remove must be implemented"
  }

  /**
   * @param {Object} resultDef
   * @param {String} resultDef.id
   * @param {String} resultDef.title
   * @param {String} resultDef.description
   * @param {String} resultDef.source
   * @param {String} resultDef.typeId
   * @param {String} resultDef.resultid
   * @param {int} resultDef.timestamp
   */
  // eslint-disable-next-line no-unused-vars
  async put(resultDef, context) {
    //Must be overridden
    throw "ResultStore.put must be implemented"
  }

  // eslint-disable-next-line no-unused-vars
  async putResult(result, context) {
    if (result.id) {
      this.put({title: result.title,
        description: result.hasDescription()  ? result.description : result.type.singular,
        image: result.image,
        source: result.source,
        typeId: result.typeId,
        resultid: result.id,
        timestamp: new Date().getTime()})
    }
  }

  //Internal
  _getSearchableData() {
    if (!this._searchableData) {
      this._searchableData = new SearchableData({
        data: this.getAll.bind(this),
        sortMode: this.sortMode,
        searchProperties: ["title", "description"],
        displaynameProperty: "title",
        idProperty: "id"
      })
    }
    return this._searchableData
  }

  async _find(id, context) {
    let resultDefs = await this.getAll(context)
    let existingResultDefIndex = resultDefs.findIndex(resultDef => resultDef.id == id)
    if (existingResultDefIndex > -1)
      return resultDefs[existingResultDefIndex]
    else
      return null
  }
  
}