/**
 * @module
 */
import QueryResult from '../QueryResult.js'
import DetailsHandlerDef from './DetailsHandlerDef.js'
import {getWKTParser} from "../util/getWKTParser.js"
import {getString} from "../resources/strings.js"
import DetailItemsList from "./DetailItemsList.js"
/**
 * Decorates a result with results that spatially overlap. Does so by querying the sq method of underlying searchers
 * @api
 */
export default class SqDetailsHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.outputType="result"] May be "result" or "labelvalue" Option to generate either a list of result items or a list of label/value items
   * @param {Object} [options.headerListItem] A detailsItem which will be shown before the lists of results
   * @param {Object} [options.footerListItem] A detailsItem which will be shown after the lists of results
   * @param {Object} [options.noResultsListItem] A detailsItem which will be shown if no results are found
   * @param {Object} [options.proxySearcher] A searcher will be queried with sq, the results of this is then used in the sq against the target
   * @param {Boolean} [options.showProxy=false] if proxySearcher is used - show the proxy?
   * @param {Boolean} [options.proxyOutputType="result"] Show the proxy as result or labelvalue
   * @param {Object} [options.searchers=[]]
   * @param {Object} [options.allow_touches=true]
   * api
   **/
  constructor(options= {}) {
    super(options)
    this.wktParser = getWKTParser()
    this._searchers = []

    this.allow_touches = true
    if (typeof options.allow_touches != 'undefined')
      this.allow_touches = options.allow_touches

    this.outputType = "result"
    if (options.outputType && options.outputType === "labelvalue")
      this.outputType = "labelvalue"
    
    this.headerListItem = null
    if (options.headerListItem)
      this.headerListItem = options.headerListItem

    this.footerListItem = null
    if (options.footerListItem)
      this.footerListItem = options.footerListItem

    this.noResultsListItem = null
    if (options.noResultsListItem)
      this.noResultsListItem = options.noResultsListItem

    this.proxySearcher = null
    if (options.proxySearcher) {
      this.proxySearcher = options.proxySearcher
      this.showProxy = false
      if (options.showProxy)
        this.showProxy = true
      this.proxyOutputType = "result"
      if (options.proxyOutputType)
        this.proxyOutputType = options.proxyOutputType
    }

    if (options.searchers)
      this.searchers = options.searchers

    this.isApplicableFunction = (result) => result.type.hasGeometry
    if (options.isApplicable)
      this.isApplicableFunction = options.isApplicable

    this.handlerFunction = this.myHandler
  }

  set searchers(sCollection) {
    for (let searcher of sCollection) 
      this.addSearcher(searcher)
  }

  get searchers() {
    return this._searchers
  }

  addSearcher(searcher) {
    this._searchers.push(searcher)
  }

  async extractQueryGeometries_delete(result) {
    let queryGeometry = result.geometry
      
    if (this.proxySearcher) {
      let resultWKT = result.searcher.translateGeoJsonObjectToWkt(result.geometry)
      let sqResult = await this.proxySearcher.sq({limit: 1, wkt: resultWKT})
      let results = sqResult.getAllResults()
      if (results.length > 0) 
        queryGeometry = results[0].geometry
      else
        return null
    }
    return queryGeometry
  }
  
  async getProxyResult(result) {
    
    let sqResult = await this.proxySearcher.sq({limit: 1, geometry: result.geometry})
    
    let results = sqResult.getAllResults()
    if (results.length > 0)
      return results[0]
    else
      return null
  }

  async myHandler(result) {
    let queryGeometry = result.geometry
    let proxyResult

    if (this.proxySearcher) {
      proxyResult = await this.getProxyResult(result)
      if (proxyResult && proxyResult.geometry)
        queryGeometry = proxyResult.geometry
      else
        return []
    }
    let items = []
    if (queryGeometry !== null) {
      if (proxyResult && this.showProxy) {
        let proxyItemList = new DetailItemsList({
          itemType: this.proxyOutputType === "result" ? "result" : "labelvalue",
          header: proxyResult.type.singular,
          name: "sq_" + proxyResult.type.id + "_proxy"
        })
        if (this.proxyOutputType === "result")
          proxyItemList.append({
            type: "result",
            result: proxyResult
          })
        else
          proxyItemList.append({
            type: "labelvalue",
            value: proxyResult.title
          })
        items.push(proxyItemList.asItem())
      }
      items = [...items, ... await this.doSq(result, queryGeometry)]

      return items
    } else {
      return [this.noResultsListItem]
    }
  }

  async doSq(result, queryGeometry) {

    const promises = []
    for (let searcher of this.searchers)
      promises.push(this.doSearcherSq(searcher, queryGeometry))


    let searcherResults = await Promise.all(promises)

    const queryResult = new QueryResult(null)
    for (let searcherResult of searcherResults)
      queryResult.addResults(searcherResult.getResults())

    let groupAndFilterResponse = this.groupAndFilterResults(queryResult)
    let types = groupAndFilterResponse.types
    let allResults = groupAndFilterResponse.allResults

    if (this.outputType === "result")
      await this.completeAllResults(allResults)
    
    let items = this.buildOutput(types)
    return items
  }

  async doSearcherSq(searcher, queryGeometry) {
    try {
      let query = {}
      let queryGeometries = []
      if (queryGeometry)
        if (queryGeometry.type.toLowerCase() === 'point' || queryGeometry.type.toLowerCase() === 'polygon')
          queryGeometries.push(queryGeometry)
        else if (queryGeometry.type.toLowerCase() === 'multipolygon')
          for (let coordinates of queryGeometry.coordinates) {
            let geometry = {
              type: "Polygon",
              coordinates
            }
            if (queryGeometry.crs)
              geometry.crs = queryGeometry.crs
            queryGeometries.push(geometry)
          }
        else if (queryGeometry.type.toLowerCase() === 'multipoint')
          for (let coordinates of queryGeometry.coordinates) {
            let geometry = {
              type: "Point",
              coordinates
            }
            if (queryGeometry.crs)
              geometry.crs = queryGeometry.crs
            queryGeometries.push(geometry)
          }
      let promises = []
      for (let queryGeometry of queryGeometries) {
        let thisQuery = Object.assign({}, query)
        thisQuery.geometry = queryGeometry
        thisQuery.allow_touches = this.allow_touches
        promises.push(searcher.sq(thisQuery))
      }
      let searcherResults = await Promise.all(promises)
      const queryResult = new QueryResult(null)
      for (let searcherResult of searcherResults)
        queryResult.addResults(searcherResult.getResults())

      return queryResult
    }catch(e) {
      // eslint-disable-next-line no-unused-vars
      let a = 2
      throw e
    }
  }
  
  async completeAllResults(results) {
    if (results.length > 0) {
      let completePromises = []
      for (let result of results) 
        completePromises.push(result.complete())
      await Promise.all(completePromises)
    }
  }

  groupAndFilterResults_org(queryResult) {
    const results = queryResult.getResults()
    const types = []
    const allResults = []
    let currentType = null
    for (let result of results) {
      if (currentType === null || result.typeId !== currentType.type.id) {
        currentType = {type: result.type, results: []}
        if (result.hasOwnProperty("image") && result.image !== null && result.image !== "")
          currentType.image = result.image
        else
          currentType.image = this.defaultTargetImage
        types.push(currentType)
      }
      currentType.results.push(result)
      allResults.push(result)
    }
    return {types: types, allResults: allResults}
  }

  groupAndFilterResults(queryResult) {
    let types = []
    let findType = (result) => {
      let typeId = result.typeId
      for (let type of types) {
        if (type.id == typeId)
          return type
      }
      let newType = {id: typeId, type: result.type, results: [], image: result.type.iconURI}
      types.push(newType)
      return newType
    } 
    
    let results = queryResult.getResults()
    let allResults = []
    for (let result of results) {
      let currentType = findType(result)
      currentType.results.push(result)
      allResults.push(result)
    }
    return {types: types, allResults: allResults}
  }
  
  buildOutput(typesWithResults) {
    let items = []

    if (typesWithResults.length === 0) {
      if (this.noResultsListItem !== null) 
        items.push(this.noResultsListItem)
      else
        items.push({
          type: "labelvalue",
          value: getString("noResults")
        })
        
    } else {
      if (this.headerListItem !== null) 
        items.push(this.headerListItem)
        
      for (let typeWithResults of typesWithResults) 
        items.push(this.buildTypeItem(typeWithResults))

      if (this.footerListItem !== null) 
        items.push(this.footerListItem)
        
    }
    return items
  }

  buildTypeItem(typeWithResults) {
    let type = typeWithResults.type
    let detailItemsList = new DetailItemsList({
      itemType: this.outputType === "result" ? "result" : "labelvalue",
      header: typeWithResults.results.length > 1 ? "" + typeWithResults.results.length + " " + type.plural : type.singular,
      image: typeWithResults.image,
      name: "sq_" + type.id + "_list"
    })
      
    for (let result of typeWithResults.results) {
      let item
      if (this.outputType === "result") {
        item = {
          type: "result",
          result: result
        }
      } else {
        item = {
          type: "labelvalue",
          value: result.title
        }
        if (result.hasDescription())
          item.description = result.description
      }
      detailItemsList.append(item)
    }
    return detailItemsList.asItem()
  }

}
