import {fetch2} from "../utils.js"

export class EnhedsAdresseAPI {
  /*
   * @param {Object} options AdresseAPI expects these options:
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {string} options.serviceUrl
  */

  constructor(options) {
    this.kommunekode  = "*"
    if (typeof options.kommunekode !== 'undefined')
      this.kommunekode = options.kommunekode.toString()
    this.serviceUrl = options.serviceUrl
  }

  query(query) {
    const callParameters = {
      q: query.query === "" ? "a" : query.query,
      side: 1,
      per_side: query.limit + 1,
      noformat: 1,
      srid: 25832
    }
    if (this.kommunekode !== "*")
      callParameters.kommunekode = this.kommunekode

    return new QueryResult(this.serviceUrl, callParameters)
  }

  async get(id) {
    let data = await fetch2(this.serviceUrl + "/adresser/" + id, {data: {srid: 25832, format: "geojson", struktur: "nestet"}})
    return data
  }

  async gets(ids) {
    let adresseFeatureCollection = await fetch2(this.serviceUrl + "/adresser/", {data: {id: ids.join("|"), srid: 25832, format: "geojson", struktur: "nestet"}})
    let features = adresseFeatureCollection.features
    adresseFeatureCollection.features = features.sort(this.featureSorter)
    return adresseFeatureCollection
  }

  async getEnhedsAdresserForAdresse(adresseId) {
    let adresseFeatureCollection = await fetch2(this.serviceUrl + "/adresser/", {data: {adgangsadresseid: adresseId, srid: 25832, format: "geojson", struktur: "nestet"}})
    //let features = adresseFeatureCollection.features
    //adresseFeatureCollection.features = features.sort(this.featureSorter)
    return adresseFeatureCollection
  }
  
  featureSorter(f1, f2) {
    let getEtageFromFeature = (f) => {
      if (!f.properties.etage) {
        return 0
      } else {
        let etageInt = parseInt(f.properties.etage)
        if (isNaN(etageInt)) {
          switch (f.properties.etage) {
            case "kl":
              return -1
            case "st":
              return 0.5
            default:
              return 0
          }
        } else {
          return etageInt
        }
      }
    }

    let getDørFromFeature = (f) => {
      if (f.properties.dør)
        return f.properties.dør
      else
        return "mf"
    }
    let etage1 = getEtageFromFeature(f1)
    let etage2 = getEtageFromFeature(f2)
    if (etage1 == etage2) {
      let dør1 = getDørFromFeature(f1)
      let dør2 = getDørFromFeature(f2)
      return (dør1.localeCompare(dør2))
    } else {
      let diff = (etage1 - etage2 )
      return diff
    } 
  }

  async getEnhedsAdresserForAdresser(adresseIder) {
    let adresser = {}
    let skyggeAdresser = {}
    let enhedsAdresser = await fetch2(this.serviceUrl + "/adresser/", {data: {adgangsadresseid: adresseIder.join("|"), srid: 25832, struktur: "mini"}})
    for (let enhedsAdresse of enhedsAdresser) {
      enhedsAdresse.type = "enhedsadresseinfo"
      enhedsAdresse.tekst = enhedsAdresse.betegnelse
      enhedsAdresse.geometry = {
        "type": "Point",
        "coordinates": [
          enhedsAdresse.x,
          enhedsAdresse.y
        ]
      }
      enhedsAdresse.geometry.crs = {
        "type": "name",
        "properties": {
          "name": "epsg:25832"
        }
      }

      let id = enhedsAdresse.adgangsadresseid
      if (enhedsAdresse.etage || enhedsAdresse.dør) {
        if (adresser[id]) {
          adresser[id].push(enhedsAdresse)
        } else {
          adresser[id] = [enhedsAdresse]
        }
      } else {
        skyggeAdresser[id] = enhedsAdresse
      }
    }
    for (let adresseId of adresseIder) {
      if (adresser[adresseId])
        adresser[adresseId].sort(this.miniSorter)
    }
    return {adresser, skyggeAdresser}
  }

  miniSorter(m1, m2) {
    let getEtageFromMini = (m) => {
      if (!m.etage) {
        return 0
      } else {
        let etageInt = parseInt(m.etage)
        if (isNaN(etageInt)) {
          switch (m.etage) {
            case "kl":
              return -1
            case "st":
              return 0.5
            default:
              return 0
          }
        } else {
          return etageInt
        }
      }
    }

    let getDørFromMini = (m) => {
      if (m.dør)
        return m.dør
      else
        return "mf"
    }
    let etage1 = getEtageFromMini(m1)
    let etage2 = getEtageFromMini(m2)
    if (etage1 == etage2) {
      let dør1 = getDørFromMini(m1)
      let dør2 = getDørFromMini(m2)
      return (dør1.localeCompare(dør2))
    } else {
      let diff = (etage1 - etage2 )
      return diff
    }

  }
}

class QueryResult {

  constructor(serviceUrl, callParameters) {
    this.serviceUrl = serviceUrl
    this.callParameters = callParameters
    this.response = null
  }
  
  async responseReady() {
    if (!this.response) {
      this.response = await fetch2(this.serviceUrl + "/adresser/autocomplete", {data: this.callParameters})
    }
  }
  
  async getCount() {
    await this.responseReady()
    return this.response.length
  }
  
  async getEnhedsAdresseHits() {
    await this.responseReady()
    let hits = []
    for (let i = 0; (i < this.response.length); i++) {
      const adr = this.response[i]
      let geometry = {
        "type": "Point",
        "coordinates": [
          adr.adresse.x,
          adr.adresse.y
        ]
      }
      geometry.crs = {
        "type": "name",
        "properties": {
          "name": "epsg:25832"
        }
      }
      hits.push({type: "enhedsadresseinfo", tekst: adr.tekst, id: adr.adresse.id, geometry: geometry})
    }
    return hits
  }

}

