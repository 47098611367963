import icons from './resources/icons.js'
import Result from './Result.js'

/**
 * 
 * @class QueryResult
 * @classdesc A collection of {@link Result}s The result from a fetchData call to a searcher. Only Searchers will create instances
 * 
 */
export default class QueryResult {
	
  constructor(searcher, defaultCrs) {
    this.defaultCrs = defaultCrs
    this._searcher = searcher
    this._results = []
    this._newQueries = []
    this._messages = []
    this._allResults = []
    this._target = this.getTarget(this._searcher)
    if (searcher && searcher.hasOwnProperty("iconURI") && searcher.iconURI !== null && searcher.iconURI !== "" && searcher.iconURI !== undefined) {
      this.newQueryIconURI = searcher.iconURI
      this.resultIconURI = searcher.iconURI
    }else{
      this.newQueryIconURI = icons.result.defaultNewQueryIcon
      this.resultIconURI = icons.result.defaultIcon
    }
  }
  
  summary() {
    return {
      results: this._results.length,
      newQueries: this._newQueries.length
    }
  }
	
  addMessage(messageOptions) {
    let message = {
      severity: messageOptions.severity ? messageOptions.severity : "info",
      title: messageOptions.title,
      description: messageOptions.description
    }
    this._messages.push(message)
  }
  
  addResult(sourceOrResult, typeId, title, description, geometry, data) {
    let result
    if (typeof sourceOrResult === 'string') {
      result = new Result({
        source: "" + sourceOrResult,
        typeId: "" + typeId,
        title: "" + title,
        description: description ? "" + description : null,
        geometry,
        data,
        "searcher": this._searcher,
        "image": this.resultIconURI
      })
    } else {
      result = sourceOrResult
    }
    if (result.geometry && this.defaultCrs && typeof result.geometry.crs == 'undefined') {
      result.geometry.crs  = {
        "type": "name",
        "properties": {
          "name": `epsg:${this.defaultCrs}`
        }
      }
    }
    if (!this.hasResult(result)) {
      this._results.push(result)
      this._allResults.push(result)
    }
    return result
  }

  hasResult(checkResult) {
    for (let addedResult of this._results) {
      if (checkResult.id && checkResult.id === addedResult.id && checkResult.source === addedResult.source && checkResult.typeId === addedResult.typeId)
        return true
    }
    return false
  }
  
  addResults(results) {
    for (let result of results) {
      if (typeof result.target === 'undefined') 
        result.target = this._target
      if (!this.hasResult(result)) {
        this._results.push(result)
        this._allResults.push(result)
      }
    }
  }
	
  addNewQuery(source, typeId, title, description, newQuery, geometry, data) {
    if (geometry && this.defaultCrs && typeof geometry.crs == 'undefined') {
      geometry.crs  = {
        "type": "name",
        "properties": {
          "name": `epsg:${this.defaultCrs}`
        }
      }
    }
    let newNewQuery = new Result({
      source,
      // Safety catches if type is string (Old way of specifying type)
      typeId: typeId,
      title,
      description: description ? description : null,
      "newquery": newQuery,
      geometry,
      data,
      "searcher": this._searcher,
      "image": this.newQueryIconURI
    })
    /*
      const newNewQuery = {
        source,
        type,
        title,
        description,
        "newquery": newQuery,
        geometry,
        data,
        "searcher": this._searcher,
        "image": this.newQueryIconURI
      };
      */
    this._newQueries.push(newNewQuery)
    this._allResults.push(newNewQuery)
    return newNewQuery
  }
	
  addThings(newThings) {
    for (let newThing of newThings) {
      if (typeof newThing.newquery != 'undefined')
        this._newQueries.push(newThing)
      else
        this._results.push(newThing)
      this._allResults.push(newThing)
    }
    
  }
  addNewQueries(newQueries) {
    for (let newQuery of newQueries) {
      this._newQueries.push(newQuery)
      this._allResults.push(newQuery)
    }
  }
	
  getSearcher() {
    return this._searcher
  }
	
  getTarget(searcher) {
    let target = "none"
    if (typeof searcher !== 'undefined' && searcher !== null) 
      if (searcher.title !== '') 
        target = searcher.title
      else
        target = searcher.getId()
      
      
    return target
  }
	
  getNewQueries() {
    return this._newQueries
  }
	
  getResults() {
    return this._results
  }
	
  getAllResults() {
    return this._allResults
    //return [...this._results, ...this._newQueries]
  }

}
