/**
 * @module
 */

import icons from '../resources/icons.js'
import EsSearcher from './EsSearcher.js'
import * as reproject from "../util/reproject.js"
import {getWKTParser} from "../util/getWKTParser.js"
//import DawaSearcher from "./DawaSearcher.js"

/**
 *
 * Searches Septima virk.dk data service<br/><br/>
 * Supports GET<br/>
 * SQ support<br/>
 * @extends module:js/searchers/EsSearcher
 * @example <caption>YAML Declaration: <a href='../examples/builder/'>Example</a></caption>
 _type: Septima.Search.CVR_enhedSearcher
 _options:
   searchindexToken: septimaSEARCHDEMO-A7OLGHG2J4
   kommunekode: 101

 * @example <caption> JS options: <a href='../examples/simple.dk/'>Example</a></caption>

 cvrSearcherOptions = {
    searchindexToken: 'TOKEN'
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.CVR_enhedSearcher(cvrSearcherOptions))
 *
 * @example <caption>ES6:</caption>
 * import CVR_enhedSearcher from './searchers/CVR_enhedSearcher.js'
 * controller.addSearcher(new CVR_enhedSearcher(cvrSearcherOptions))
 *
 * @api
 */

export default class CVR_enhedSearcher extends EsSearcher {
  /**
   *
   * @param {Object} options CVR_enhedSearcher expects these properties:
   * @param {string} options.searchindexToken Please contact Septima to obtain a valid token
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {string} [options.singular=Produktionsenhed]
   * @param {string} [options.plural=Produktionsenheder]
   * @api
   */

  constructor(options) {
    options.fields = options.fields || ['navn_tekst',
      'beliggenhedsadresse_vejnavn',
      'beliggenhedsadresse_husnummerfra',
      'beliggenhedsadresse_postdistrikt',
      'beliggenhedsadresse_postnr',
      'pnr',
      'wkt',
      'septima_addressaccessidentifier',
      'virksomhed_cvrnr',
      'hovedbranche_tekst',
      'telefonnummer_kontaktoplysning',
      'email_kontaktoplysning']
    options.searchindexServiceUrl = 'https://new-searchindex.septima.dk/'
    options.index = options.searchindexToken + '/cvr/produktionsenheder'

    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += ''
      let municipalities = options.kommunekode.split(' ')
      options.filterTerm = { 'beliggenhedsadresse_kommune_kode' : municipalities }
    }else if (options.filter) {
      options.filterTerm = options.filter
    }

    options.source = "cvr"
    options.typeId = "produktionsenhed"
    if (typeof options.singular === 'undefined')
      options.singular = 'Produktionsenhed'
    if (typeof options.plural === 'undefined')
      options.plural = 'Produktionsenheder'
    options.idField = 'pnr'


    super(Object.assign({
      iconURI:icons.searchers.cvr
    },
    options))
    this.searchindexToken = options.searchindexToken

    reproject.registerCrs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")

  }

  createQuery(queryString) {
    let esQuery
    esQuery ={
      "bool": {
        "should": [
          {"multi_match": {
            "query":       queryString,
            "type":        "cross_fields",
            "operator":    "and",
            "fields":      ["hovedbranche_tekst","navn_tekst","pnr", "virksomhed_cvrnr"]
          }},
          {
            "match_phrase": {
              "navn_tekst": {
                "boost": 5,
                "query": queryString
              }
            }
          },
          {
            "match": {
              "navn_tekst": {
                "boost": 4,
                "operator": "or",
                "query": queryString
              }
            }
          },
          {
            "match": {
              "beliggenhedsadresse_vejnavn": {
                "boost": 3,
                "query": queryString
              }
            }
          },
          {
            "match_phrase": {
              "hovedbranche_tekst": {
                "boost": 2,
                "query": queryString
              }
            }
          }
        ]
      }
    }
    return esQuery
  }

  async sq(query) {
    const queryResult = this.createQueryResult()
    if (query.geometry) {
      let queryGeometry = reproject.reproject(query.geometry, null, "EPSG:25832")
      let wktParser = getWKTParser()
      const queryWKT = wktParser.convert(queryGeometry)
      const attributeFilter = query.attributeFilter || this.attributeFilter

      let cql_filter = `intersects(septima_geometri,${queryWKT})`
      if (this.distanceWithin)
        cql_filter = `dwithin(septima_geometri,${queryWKT},${this.distanceWithin},meters)`
      if (query.ignoreGeometry)
        cql_filter = '1=1'
      if (attributeFilter) {
        const cql_attribute_filter = Object
          .keys(attributeFilter)
          .map(key => `${key} in (${attributeFilter[key].join(',')})`)
          .join(' or ')
        cql_filter += ` and (${cql_attribute_filter})`
      }

      let params = {
        servicename: 'WFS',
        typename: 'stamdata:produktionsenheder',
        request: 'GetFeature',
        version: '1.0.0',
        outputformat: 'json',
        cql_filter
      }

      const url = 'http://g.septima.dk/geoserver/stamdata/wfs'
      const fc = await this.fetch(url, { data: params, method: 'post' })


      fc.features.forEach(f => {
        let title = f.properties.navn_navn
        let description = f.properties.hovedbranche_branchetekst
        let geometry = f.geometry
        let result = queryResult.addResult(this.source, "produktionsenhed", title, description, geometry, f)
        result.id = f.properties.pnummer
      })
    }
    return queryResult
  }

  getGeometry(hit) {
    if (hit.fields.wkt !== undefined) 
      return this.translateWktToGeoJsonObject(hit.fields.wkt[0], "25832")
    return null
  }
  
  async getVirksomhed(cvrNummer) {
    let virksomhedsUrl = this.searchindexServiceUrl + "/" + this.searchindexToken + "/cvr/virksomheder/" + cvrNummer
    let virksomhed = await this.fetch (virksomhedsUrl)
    return virksomhed
  }
  
  async getProduktionsEnheder(cvrNummer, komnumre) {
    //let produktionsenhederUrl = this.searchindexServiceUrl + "/" + this.searchindexToken + "/cvr/produktionsenheder/_search?size=1000&sort=navn_tekst:asc&q=virksomhed_cvrnr:" + cvrNummer
    let produktionsenhederUrl
    if (komnumre)
      produktionsenhederUrl = `http://g.septima.dk/geoserver/stamdata/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=stamdata%3Aproduktionsenheder&outputformat=json&cql_filter=cvrnummerrelation=${cvrNummer} AND beliggenhedsadresse_kommune_kommunekode IN (${komnumre})`
    else
      produktionsenhederUrl = `http://g.septima.dk/geoserver/stamdata/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=stamdata%3Aproduktionsenheder&outputformat=json&cql_filter=cvrnummerrelation=${cvrNummer}`
    let produktionsenhederResponse = await this.fetch (produktionsenhederUrl)
    let queryResult = this.createQueryResult()
    let pnumre =  produktionsenhederResponse.features.map(p => p.properties.pnummer)
    for (let pnr of pnumre) {
      let penhed =  await this.get(pnr)
      queryResult.addResult(penhed)
    }
    return queryResult
  }
  
  async getVirksomhederForAdresse(vejkode, husnummer, komkode) {    
    let vejkodeFixed 

    let index0=vejkode.charAt(0)
    let index1=vejkode.charAt(1)

    if (index0==0) {
      vejkodeFixed = vejkode.substring(1)
    }
    if  ( index0==0 && index1==0)   {
      vejkodeFixed = vejkode.substring(2)
    }
    if (index0 !=0 && index1!=0) {
      vejkodeFixed = vejkode
    }
    
    //let url = this.searchindexServiceUrl + "/" + this.searchindexToken + `/cvr/produktionsenheder/_search?q=beliggenhedsadresse_vejkode:${vejkode} AND beliggenhedsadresse_husnummerfra:${husnummer} AND beliggenhedsadresse_kommune_kode=${komkode.substr(-3)}`
    let virksomhederUrl = this.searchindexServiceUrl + "/" + this.searchindexToken + "/cvr/produktionsenheder/_search?q=beliggenhedsadresse_vejkode:"+vejkodeFixed+" AND beliggenhedsadresse_husnummerfra:"+husnummer+" AND beliggenhedsadresse_kommune_kode=" + komkode.substr(-3)+'&size=100000'
    let produktionsenhederResponse = await this.fetch (virksomhederUrl)
    let queryResult = this.createQueryResult()
    let pnumre =  produktionsenhederResponse.hits.hits.map(p => p._id)
    for (let pnr of pnumre) {
      let penhed =  await this.get(pnr)      
      queryResult.addResult(penhed)
    }
    return queryResult
  }


  getDescription(hit) {   
    return hit.fields.beliggenhedsadresse_vejnavn[0] + 
      ' ' + (hit.fields.beliggenhedsadresse_husnummerfra  ?  hit.fields.beliggenhedsadresse_husnummerfra[0] : ' ' ) + 
      ' ' + (hit.fields.beliggenhedsadresse_postnr  ? hit.fields.beliggenhedsadresse_postnr[0] : ' ' ) +
      ' ' + ( hit.fields.beliggenhedsadresse_postdistrikt  ? hit.fields.beliggenhedsadresse_postdistrikt[0] : ''  )
  }

  getDisplayName(hit) {
    return hit.fields.navn_tekst[0]
  }
  
}
