/**
 * @module
 */

import icons from "../resources/icons.js"

/**
 * Decorates a result
 * @api
 */
export default class DetailsHandlerDef {

  /**
   * @param {Object} options
   * @param {boolean} [options.more=true] Indicates whether the details provided should be considered an extension of the result rather than related information (Layouts may show "more" handlers at the top without specific heading)
   * @param {boolean} [options.targets=[{source: '*', typeId: '*'}]] Arrayof Combinations of source/typeIds that are supported by the handler
   * @param {string} [options.buttonText=""] Title of the handler (Layouts may show the title as tab caption)
   * @param {string} [options.buttonImage=icons.infoGrey] Url to icon of the handler (Layouts may show the image as part of the tab caption)
   * @param {string} [options.description=""] Description (e.g metadata of output) of the handler (Layouts may show the the description directly or as a link)
   * @param {Object} [options.logger]
   * @param {string} [options.logLevel]
   * @param {string} [options.id=random unique string]
   * @param {function} [options.isApplicable=check against options.targets] function, (result)=>, return boolean indicating whether the result is handled
   * @param {function} options.handler function, (result)=>, return detail items
   * api
   **/
  constructor(options= {}) {

    this.more = false
    this.targets = [{source: '*', typeId: '*'}]
    this.buttonImage = icons.infoGrey
    this.description = ""
    this.id = "DetailsHandler" + "_" + Math.floor(Math.random() * 999999999)
    this.buttonText = this.id
    this.detailHandlerDefs = []
    this.handlerFunction = async()=>{
      return[]
    }

    this.isApplicableFunction = (result)=>{
      return this.hasTarget(result.source, result.typeId)
    }

    if (options.targets) {
      this.targets = []
      for (let target of options.targets) {
        if (typeof target.source == 'undefined' || target.source === null)
          target.source = "*"

        if (typeof target.typeId == 'undefined' || target.typeId === null) {
          this.targets.push({source: target.source, typeId: "*"})
        } else if(Array.isArray(target.typeId)) {
          for (let typeId of target.typeId)
            this.targets.push({source: target.source, typeId})
        } else {
          this.targets.push({source: target.source, typeId: target.typeId})
        }

      }

    }

    if (options.id) {
      this.id = options.id
      this.buttonText = this.id
    }

    if (options.more)
      this.more = options.more

    if (typeof options.buttonText !== 'undefined')
      this.buttonText = options.buttonText

    if (options.buttonImage)
      this.buttonImage = options.buttonImage

    if (options.description)
      this.description = options.description

    if (options.handler)
      this.handlerFunction = options.handler

    if (options.isApplicable)
      this.isApplicableFunction = options.isApplicable

    if (options.renderHints)
      this.renderHints = options.renderHints

    if (options.detailhandlers)
      this.detailhandlers = options.detailhandlers

    if (options.logger) {
      let childOptions = {
        module: this.id + "(" + this.buttonText + ")"
      }
      if (options.logLevel)
        childOptions.level = options.logLevel
      this._logger = options.logger.child(childOptions)
    }
  }

  hasTarget(source, typeId) {
    for (let target of this.targets) {
      if ((target.source === '*' || target.source.toLowerCase() === source.toLowerCase()) && (target.typeId === '*' || target.typeId.toLowerCase() === typeId.toLowerCase()))
        return true
    }
    return false
  }

  getId() {
    return this.id
  }

  set detailhandlers(dhArray) {
    for (let detailhandler of dhArray)
      this.addDetailHandlerDef(detailhandler)
  }

  /**
   *
   * @param {module:js/details/DetailsHandlerDef} detailHandlerDef
   * @param typeId
   */
  addDetailHandlerDef(detailHandlerDef) {
    this.detailHandlerDefs.push(detailHandlerDef)
  }

  getLogger() {
    return this._logger
  }

  getbuttonText() {
    return this.buttonText
  }

  getbuttonImage() {
    return this.buttonImage
  }

  async handler(result, renderAsMore, contextResult) {
    let logger = this.getLogger()
    try {
      let detailItems = await this.handleThisOrRelated(result, renderAsMore, contextResult)
      if (this.detailHandlerDefs.length>0)
        for (let item of detailItems)
          if (item.type === "result")
            await this.decorateResultItem(item, result)
          else if (item.type === "list" && item.itemType === 'result' && item.items.length > 0)
            await this.decorateResultList(item, result)
      if (logger) {
        logger.trace({function:'_handle', result: result.summary(), detailItems})
        //logger.debug({function:'_handle', itemCount: detailItems.length})
      }
      return detailItems
    }catch(e) {
      if (logger)
        logger.error(Object.assign({function:'_handle'}, {message: e.message}))
      throw e
    }
  }

  async handleThisOrRelated(result, renderAsMore, contextResult) {
    let logger = this.getLogger()

    if (this.isApplicable(result, contextResult)) {
      return await this._handle(result, renderAsMore, logger, contextResult)
    } else {
      //Try parents
      let relations = await result.getRelations()
      let parentResults = relations.parents
      for (let parentResult of parentResults)
        if (this.isApplicable(parentResult))
          return await this._handle(parentResult, renderAsMore, logger, contextResult)
    }
    return []
  }

  async _handle(result, renderAsMore, logger, contextResult) {
    let handlerResult
    if (typeof renderAsMore !== 'undefined' && renderAsMore !== this.more) {
      this.more = !this.more
      handlerResult = await this.handlerFunction(result, logger, contextResult)
      this.more = !this.more
    }else {
      handlerResult = await this.handlerFunction(result, logger, contextResult)
    }
    return handlerResult
  }


  async decorateResultItem(resultItem, contextResult) {
    for (let detailHandlerDef of this.detailHandlerDefs)
      if (detailHandlerDef.isApplicable(resultItem.result, contextResult)) {
        let detailItems = await detailHandlerDef.handler(resultItem.result, true, contextResult)
        let relevantDetailItems = detailItems.filter(item => (item.type !== "list"))
        let infoItemsHeaders = this.extractItemHeaders(relevantDetailItems)
        resultItem.infoItemsHeaders = infoItemsHeaders
        resultItem.infoItems = relevantDetailItems
        break
      }
  }

  async decorateResultList(resultList, contextResult) {
    let firstResultItem = resultList.items[0]
    for (let detailHandlerDef of this.detailHandlerDefs)
      if (detailHandlerDef.isApplicable(firstResultItem.result, contextResult)) {
        let firstDetailItemsPromise = detailHandlerDef.handler(firstResultItem.result, true, contextResult)
        let otherDetailItemsPromises = []
        let todoArray = []
        for (let j = 1; j < resultList.items.length; j++) {
          let resultItem = resultList.items[j]
          let detailItemsPromise = detailHandlerDef.handler(resultItem.result, true, contextResult)
          otherDetailItemsPromises.push(otherDetailItemsPromises)
          todoArray.push({resultItem, detailItemsPromise})
        }

        let firstDetailItems = await firstDetailItemsPromise
        let relevantDetailItems = firstDetailItems.filter(item => (item.type !== "list"))
        let infoItemsHeaders = this.extractItemHeaders(relevantDetailItems)
        resultList.infoItemsHeaders = infoItemsHeaders
        firstResultItem.infoItems = relevantDetailItems

        await Promise.all(otherDetailItemsPromises)
        for (let todo of todoArray) {
          let detailItems = await todo.detailItemsPromise
          let resultItem = todo.resultItem
          let relevantDetailItems = detailItems.filter(item => (item.type !== "list"))
          resultItem.infoItems = relevantDetailItems
        }

        break

      }
  }

  extractItemHeaders(items) {
    let headerItems = []
    for (let item of items)
      if (item.type === "result") {
        let result = item.result
        let label = result.type.singular
        headerItems.push({
          type: "result",
          label: label
        })
      } else if(item.type === "labelvalue") {
        headerItems.push({
          type: "labelvalue",
          label: item.label,
          valueformat: item.valueformat
        })
      } else if (item.type === "link") {
        headerItems.push({
          type: "link",
          label: item.value
        })
      }else if (item.type === "image") {
        headerItems.push({
          type: "image"
        })
      }
    return headerItems
  }

  isApplicable(result, contextResult) {
    if (this.hasTarget(result.source, result.typeId))
      return this.isApplicableFunction(result, contextResult)
    else
      return false
  }
}