/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
import DawaSearcher from "../DawaSearcher.js"
import DetailItemsList from "../../details/DetailItemsList.js"
/**
 * Viser info for cvr.produktionsenhed eller cvr.virksomhed resultater
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.CVR_enhedSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.CvrInfoProvider
 *     _options:
 *       fields: ["hovedbranche","navn","email","tlf","adresse","adresse_result","cvrlink"]
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.CvrInfoProvider({more: false}))
 *
 * @example <caption>ES6:</caption>
 * import CvrLinkHandler from './searchers/detailhandlers/CvrInfoProvider.js'
 * dawaSearcher.addDetailHandlerDef(new CvrInfoProvider({more: false}))
 * @api
 **/
export default class CvrInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {String[]} [options.fields=["pnummer","hovedbranche", "navn", "adresse_result", "email", "tlf", "cvrlink", "virksomhed_navn", "virksomhed_cvrlink"]]
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "CVR-info",
      buttonImage: icons.searchers.cvr,
      komnumre: null,
      isApplicable: (result) => {
        return ((result.source === "cvr" && result.typeId === "produktionsenhed") || (result.source === "cvr" && result.typeId === "virksomhed"))
      }
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler

    this.fields = ["hovedbranche", "navn", "adresse_result", "email", "tlf", "cvrlink", "virksomhed_navn", "virksomhed_cvrlink", "virksomhed_enhedsliste"]
    if (typeof options.fields !== 'undefined')
      this.fields = options.fields

    if (typeof options.virksomhed_enhedsliste_komnumre !== 'undefined')
      this.komnumre = options.virksomhed_enhedsliste_komnumre

    this.dawaSearcher = new DawaSearcher({})
  }

  async myHandler(result) {
    //Find virksomheden fra cvr nummer
    let cvrNummer = result.data.fields.virksomhed_cvrnr[0]
    let virksomhedsInfo = await result.searcher.getVirksomhed(cvrNummer)

    let items = []
    for (var f of this.fields) {
      let item = await this.createItem(result, f, virksomhedsInfo)
      if (item)
        items.push(item)
    }
    return items
  }
  async createItem(result, field, virksomhedsInfo) {

    if (field === 'cvrlink') {
      return {
        type: 'link',
        icon: icons.bbrhouse,
        link: `http://datacvr.virk.dk/data/visenhed?enhedstype=produktionsenhed&id=${result.data.fields.pnr[0]}`,
        linkTitle: `Produktionsenheden (${result.id}) på virk.dk`
      }
    } else if (field === 'navn') {
      return {
        type: 'labelvalue',
        label: `Navn`,
        value: `${result.data.fields.navn_tekst[0]}`
      }
    } else if (field === 'hovedbranche') {
      return {
        type: 'labelvalue',
        label: `Hovedbranche`,
        value: `${result.data.fields.hovedbranche_tekst[0]}`
      }
    } else if (field === 'pnummer') {
      return {
        type: 'labelvalue',
        label: `P-nummer`,
        value: `${result.id}`
      }
    } else if (field === 'email') {
      let email
      if (typeof result.data.fields.email_kontaktoplysning === 'undefined') {
        return {
          type: 'labelvalue',
          label: 'Email',
          value: 'Ikke angivet'
        }
      } else
        email = result.data.fields.email_kontaktoplysning
      return {
        type: 'link',
        label: `Email`,
        link: 'mailto:' + email,
        linkTitle: email
      }
    } else if (field === 'tlf') {
      let tlf
      if (typeof result.data.fields.telefonnummer_kontaktoplysning === 'undefined') {
        return {
          type: 'labelvalue',
          label: 'Telefon',
          value: 'Ikke angivet'
        }
      } else
        tlf = result.data.fields.telefonnummer_kontaktoplysning
      return {
        type: 'link',
        label: `Telefon`,
        value: tlf,
        link: 'tel:' + tlf,
        linkTitle: tlf
      }
    } else if (field === 'adresse_result') {
      if (result.data.fields.septima_addressaccessidentifier && result.data.fields.septima_addressaccessidentifier.length > 0) {
        let adresseResult = await this.dawaSearcher.get(result.data.fields.septima_addressaccessidentifier[0], 'adresse')
        return {
          type: 'result',
          label: 'Adresse',
          result: adresseResult
        }
      }
    } else if (field === 'adresse') {
      let husnummer
      if (typeof beliggenhedsadresse_husnummerfra === 'undefined')
        husnummer = ''
      else husnummer = result.data.fields.beliggenhedsadresse_husnummerfra
      return {
        type: 'labelvalue',
        label: `Adresse`,
        value: `${result.data.fields.beliggenhedsadresse_vejnavn} ${husnummer}, ${result.data.fields.beliggenhedsadresse_postnr[0]} ${result.data.fields.beliggenhedsadresse_postdistrikt[0]}`
      }
    } else if (field === 'virksomhed_navn') {
      let virksomhedsNavn = virksomhedsInfo._source.navn_tekst
      return {
        type: 'labelvalue',
        label: `Virksomhed`,
        value: virksomhedsNavn
      }
    } else if (field === 'virksomhed_cvrlink') {
      let cvrNummer = virksomhedsInfo._source.cvrnr
      return {
        type: 'link',
        icon: icons.exlink,
        link: `http://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${cvrNummer}`,
        linkTitle: `Virksomheden (${cvrNummer}) på virk.dk`
      }

    } else if (field === 'virksomhed_enhedsliste') {
      let cvrNummer = virksomhedsInfo._source.cvrnr
      let queryResult = await result.searcher.getProduktionsEnheder(cvrNummer, this.komnumre)
      let pResults = queryResult.getResults()
      if (pResults.length > 1) {
        let detailItemsList = new DetailItemsList({ itemType: "result", header: "Virksomhedens produktionsenheder", image: pResults[0].image })
        for (let pResult of pResults)
          detailItemsList.append({
            type: "result",
            result: pResult,
            name: "cvr_info_produktionsenheder"
          })
        return detailItemsList.asItem()
      }

    }

  }
}