/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
/**
 * Viser info for FKG objekter
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:<a href='../examples/fkgsearcher/'>Example</a></caption>
    plansystemsearcher:
      _type: Septima.Search.FkgSearcher
      _options:
        minimumShowCount: 3
        targets:
          - t_5710_born_skole_dis_t
      detailhandlers:
        - _type: "Septima.Search.FkgInfoProvider"
          _options:
            fields:
              t_5710_born_skole_dis_t: ['temanavn','udd_distrikt_navn']
 * @example <caption> JS options: </caption>
 options = {
    fields: {
      t_5710_born_skole_dis_t: [temanavn','udd_distrikt_navn','cvr_navn','cvr_kode','starttrin','slutttrin']
    }
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * planSearcher.addDetailHandlerDef(new Septima.Search.FkgInfoProvider(options))
 *
 * @example <caption>ES6:</caption>
 * import PlanInfoProvider from './searchers/detailhandlers/FkgInfoProvider.js'
 * planSearcher.addDetailHandlerDef(new FkgInfoProvider(options))
 * @api
 **/

export default class FkgInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields] Felter, der skal medtages i den viste info
   * @param {string[]} [options.fields.t_5710_born_skole_dis_t= ["temanavn","cvr_navn","cvr_kode","starttrin","slutttrin"]] For skoledistrikter. BEMÆRK  slutttrin har et t for meget

   **/
  constructor(options) {
    let defaultOptions  = {
      buttonText: "FKG info",
      more: true
    } 
    super(Object.assign(defaultOptions, options))
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.handler

    //Default fields
    this.fields = {}
    this.fields.t_5710_born_skole_dis_t = ["temanavn", "udd_distrikt_navn","cvr_kode","cvr_navn","starttrin","slutttrin"]

    //Read fields from options
    if (typeof options !== 'undefined' && typeof options.fields !== 'undefined') 
      if (typeof options.fields.t_5710_born_skole_dis_t !== 'undefined')
        this.fields.t_5710_born_skole_dis_t = options.fields.t_5710_born_skole_dis_t    
  }

  isApplicable(result) {
    return (result.source === "fkg")
  }
  
  async handler(result) {

 
 
    let items = []

    if (result.typeId === "t_5710_born_skole_dis_t")
      for (let field of this.fields.t_5710_born_skole_dis_t) {
        let item = await this.create_t_5710_born_skole_dis_t_Item(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }
    
    return items

  }

  create_t_5710_born_skole_dis_t_Item(result, field) {
    let props = result.data.properties
    if (field === 'udd_distrikt_navn')
      return {
        type: 'labelvalue',
        label: 'Navn',
        value:  props.udd_distrikt_navn
      }
    else if (field === 'temanavn')
      return {
        type: 'labelvalue',
        label: 'Tema',
        value: props.temanavn

      }
    else if (field === 'cvr_kode')
      return {
        type: 'labelvalue',
        label: 'CVR Kode',
        value: props.cvr_kode

      }
    else if (field === 'cvr_navn')
      return {
        type: 'labelvalue',
        label: 'CVR Navn',
        value: props.cvr_navn

      }
    else if (field === 'starttrin')
      return {
        type: 'labelvalue',
        label: 'Starttrin',
        value: props.starttrin

      }
    else if (field === 'slutttrin')
      return {
        type: 'labelvalue',
        label: 'Sluttrin',
        value: props.slutttrin

      }
    return
  }

}