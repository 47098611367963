/**
 * @module
 */

import SearchableData from './SearchableData.js'

/** 
 *  
 * Makes a geojson featurecollection searchable
 * @extends module:js/searchers/local/SearchableData
 *@example
 * <!-- Include septimaSearch -->
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 var schools = {
  "type": "FeatureCollection",
  "features": [{
    "id": 1,
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [724837.68, 6175474.03]
    },
    "properties": {
      "navn": "Den Classenske Legatskole",
      "ogc_fid": 1,
      "omraade": "Indre By",
      "antalborn": "481",
      "skoleledernavn": "Lone Reichstein Laursen",
      "hjemmesideadresse": "http://www.dcl.kk.dk"
    }
  }, {
    "id": 2,
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [725231.21, 6177008.34]
    },
    "properties": {
      "navn": "Sølvgades Skole",
      "ogc_fid": 2,
      "omraade": "Indre By",
      "antalborn": "452",
      "skoleledernavn": "Ulla-Britt Sabroe",
      "hjemmesideadresse": "http://www.soel.kk.dk"
    }
  }, {
    "id": 3,
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [725396.37, 6177471.75]
    },
    "properties": {
      "navn": "Nyboder Skole",
      "ogc_fid": 3,
      "omraade": "Indre By",
      "antalborn": "550",
      "skoleledernavn": "Anders Mikkelsen",
      "hjemmesideadresse": "http://www.nyboderskole.kk.dk"
    }
  }, {
    "id": 4,
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [724839.83, 6177665.12]
    },
    "properties": {
      "navn": "Øster Farimagsgades Skole",
      "ogc_fid": 4,
      "omraade": "Indre By",
      "antalborn": "606",
      "skoleledernavn": "Axel Bech",
      "hjemmesideadresse": "http://www.oef.kk.dk"
    }
  }]
}

 var searchableGeoJSOn = new Septima.Search.SearchableGeoJson({
    singular: "School",
    plural: "Schools",
    data: schools,
    searchProperties: ["navn", "omraade"],
    displaynameProperty: "navn",
    descriptionProperty: "omraade",
    idProperty: "ogc_fid"
  })

 var schoolSearcher = new Septima.Search.DataSearcher({
    searchableData: searchableGeoJSOn
  })

 controller.addSearcher (schoolSearcher)
 *
 * @param {Object} options SearchableData expects these properties:
 * @param options.data {object} GeoJSON object or a function that returns a GeoJSON object. If data is a function it will be called each time fetchData is called. This way the client could change the data on the fly and not keep them static. This could be used when adding an external filter.
 * @param options.searchProperties {string[]} Array of property names search in. If not added, all properties will be used
 * @param options.displaynameProperty {string} The name of the property in the GeoJSON object that should be used as displayname
 * @param options.descriptionProperty {string} The name of the property in the GeoJSON object that should be used as description
 * @param options.useAND {boolean} Use AND and not OR when multiple terms is added by the user. Default true
 * @param options.singular {string} Singular phrase, eg.: "feature"
 * @param options.plural {string} Plural phrase, eg.: "features"
 * @api
 */
export default class SearchableGeoJson extends SearchableData {
	
  async _getData() {
    //let data = await SearchableData.prototype._getData.apply(this)
    let data = await super._getData()
    return data.features
  }

  getDisplayname(feature) {
    let displayName = super.getDisplayname(feature.properties) 
    return displayName
    //return SearchableData.prototype.getDisplayname.apply(this, [feature.properties])
  }
	
  getDescription(feature) {
    return super.getDescription(feature.properties)
    //return SearchableData.prototype.getDescription.apply(this, [feature.properties])
  }
  
  getId(feature) {
    let id = null
    if (this.idProperty) 
      id = feature.properties[this.idProperty]
    else if (feature.id)
      id = feature.id
    
    return id
  }

  match(hit, str) {
    const feature = hit.object
    hit.object = feature.properties
    let matchResult = super.match(hit, str)
    hit.object = feature
    return matchResult
  }
  
  getGeometry(feature) {
    return feature.geometry
  }
}
