import WKT from 'ol/format/WKT.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import Point from 'ol/geom/Point.js'

function parse(wkt, crsEpsg) {
  const geometry = new WKT().readGeometry(wkt)
  const geojsonObject = new GeoJSON().writeGeometryObject(geometry)
  if (crsEpsg) {
    geojsonObject.crs = {
      "type": "name",
      "properties": {
        "name": `epsg:${crsEpsg}`
      }
    }
  }
  return geojsonObject
}

function convert(geojson) {
  const geometry = new GeoJSON().readGeometry(geojson)
  const wkt = new WKT().writeGeometry(geometry)
  return wkt
}

function getInteriorPoint(geometry) {
  // Accepts wkt and geoJson - returns same type
  let olGeom
  if (isObject(geometry)) {
    //geojson
    olGeom = new GeoJSON().readGeometry(geometry)
  } else {
    olGeom = new WKT().readGeometry(geometry)
  }
  let interiorPoint = olGeom.getInteriorPoint()
  
  //Fix coorcintes if Z or M returned
  let coordinates = interiorPoint.getCoordinates()
  if (coordinates.length > 2)
    coordinates = [coordinates[0], coordinates[1]]
  interiorPoint = new Point(coordinates)
  
  if (isObject(geometry)) {
    let returnObject = new GeoJSON().writeGeometryObject(interiorPoint)
    if (geometry.crs)
      returnObject.crs = geometry.crs
    return returnObject
  } else {
    return new WKT().writeGeometry(interiorPoint)
  }
}

function isObject(val) {
  if (val === null) {
    return false
  }
  return ( (typeof val === 'function') || (typeof val === 'object') )
}

export function getWKTParser() {
  return {
    parse,
    convert,
    getInteriorPoint
  }
}
