/**
 * @module
 */
import Searcher from './Searcher.js'
import icons from "../resources/icons.js"
import ResultType from "../ResultType.js"

export default class EsSearcher extends Searcher {

  constructor(options) {
    if (typeof options === 'undefined') 
      throw "EsSearcher.initialize: No options"
    
    if (!options.source) 
      throw "EsSearcher.initialize: No source"
    
    super(Object.assign({
      usesGeoFunctions: true,
      iconURI:icons.result.defaultIcon
    },
    options))
    
    this.source = options.source
    this.typeId = options.singular
    if (options.typeId) 
      this.typeId = options.typeId
    this.resultType = new ResultType({
      id: this.typeId,
      singular: options.singular,
      plural: options.plural
    })
    
    this.registerType(this.source, this.resultType)

    this.fields = options.fields
    this.searchindexServiceUrl = options.searchindexServiceUrl
    this.index = options.index
    if (options.filterTerm) 
      this.filterTerm = options.filterTerm
    this.idField = null
    if (options.idField) 
      this.idField = options.idField
  }

  getDefaultSort() {
    //Override this method for a correct default sort.
    //Return a valid ES sort object (https://www.elastic.co/guide/en/elasticsearch/guide/current/_sorting.html)
    //Default behaviour: Search on first field. May very well not work as ES can't sort analyzed fields (https://www.elastic.co/guide/en/elasticsearch/guide/current/multi-fields.html)
    const defaulSort = {}
    defaulSort[this.fields[0]] = {"order": "asc"}
    return defaulSort
  }

  createQueryString(query) {
    //http://www.elasticsearch.org/guide/en/elasticsearch/guide/current/multi-field-search.html
    const data = {
      fields: this.fields,
      size: query.limit + 3
    }

    const queryString = query.queryString

    if (queryString === "") {
      data.query = {"match_all": {}}
      data.sort = this.getDefaultSort()
    } else {
      data.query = this.createQuery(queryString)
    }
    //if (queryString)
    //http://www.elasticsearch.org/guide/en/elasticsearch/reference/current/search-request-sort.html
    if (this.filterTerm) 
      if (JSON.stringify(this.filterTerm).indexOf("[") > 0) 
        data.filter = {
          query: {
            terms: this.filterTerm
          }
        }
      else 
        data.filter = {
          query: {
            term: this.filterTerm
          }
        }
      
    
    return JSON.stringify(data)
  }

  createLocateString(id) {
    const data = {
      fields: this.fields,
      size: 1
    }

    data.query = {
      query_string: {
        default_field: this.idField,
        "query": this.idField + ":" + id
      }
    }
    return JSON.stringify(data)
  }

  async get(id) {
    if (this.idField !== null) {
      await this.ready()
      let queryResult = this.createQueryResult()
      let data = await this.fetch(this.searchindexServiceUrl + this.index + '/_search', {data: {source: this.createLocateString(id)}})
      if (data.hits.hits.length === 1) {
        let thisHit = data.hits.hits[0]
        let displayName = this.getDisplayName(thisHit)
        let description = this.getDescription(thisHit)
        let resultGeometry = this.getGeometry(thisHit)
        let result = queryResult.addResult(this.source, this.typeId, displayName, description, resultGeometry, thisHit)
        result.id = id
        return result
      } else {
        throw new Error('Whoops! EsSearcher-' + this.source + ' could not locate ' + this.idField + ": " + id)
      }
    } else {
      throw new Error('Whoops! get(id) is not implemented in EsSearcher-' + this.source)
    }
  }

  async fetchData(query, caller) { 
    if (query.type === 'collapse') {
      let queryResult = this.createQueryResult()
      let result = queryResult.addNewQuery(this.source, this.typeId, this.resultType.plural, null, '', null, null, null)
      result.image = this.iconURI
      caller.fetchSuccess(queryResult)
    }else {
      try{
        let source = this.createQueryString(query)
        if (source.length < 7000) {
          let data = await this.fetch(this.searchindexServiceUrl + this.index + '/_search', {data: {source: source}})
          data.query = query
          let result = this.parseResult(data)
          caller.fetchSuccess(result)
        } else {
          caller.fetchSuccess(this.createQueryResult())
        }
      }catch(error) {
        caller.fetchError(error)
      }
    }
  }

  parseResult(data) {
    try {
      const queryResult = this.createQueryResult()
      const query = data.query
      const totalCount = data.hits.total
      const count = data.hits.hits.length
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)

      for (let hit of data.hits.hits.slice(0, hitsShown)) 
        this.addResultFromHit(query, queryResult, hit)

      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        if (hitsShown > 0)
          queryResult.addNewQuery(this.source, this.typeId, "Flere " + this.resultType.plural + " (" + totalCount + ")", null, query.queryString, null, null)
        else
          queryResult.addNewQuery(this.source, this.typeId, this.resultType.plural + " (" + totalCount + ")", null, query.queryString, null, null)
      }

      return queryResult
    } catch (e) {
      throw ("Error in parseResult: " + e.message)
    }
  }
  
  addResultFromHit(query, queryResult, hit) {
    let displayName = this.getDisplayName(hit)
    if (query.type === "cut")
      displayName += " (" + this.resultType.singular + ")"

    let description = this.getDescription(hit)
    let resultGeometry = this.getGeometry(hit)
    if (this.idField !== null) {
      let result = queryResult.addResult(this.source, this.typeId, displayName, description, resultGeometry, hit)
      result.id = this.getIdValue(hit)
      result.isComplete = false
    }else{
      queryResult.addResult(this.source, this.typeId, displayName, description, resultGeometry, hit)
    }
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      result.isComplete = true
      let gotten = await this.get(result.id)
      result.data = gotten.data
      return result
    }
  }

  getIdValue(hit) {
    return hit.fields[this.idField][0]
  }

  getDescription() {
    throw "EsSearcher.getDescription MUST be overwritten by implementation"
  }

  getDisplayName() {
    throw "EsSearcher.getDisplayName MUST be overwritten by implementation"
  }

  getGeometry() {
    throw "EsSearcher.getGeometry MUST be overwritten by implementation"
  }

  createQuery() {
    throw "EsSearcher.createQuery MUST be overwritten by implementation"
  }

  async getRelations(result) {
    return await this.getRelationsForHit(result.data)
  }

  //This function may be overridden by implementations of EsSearcher
  //Override with signature getRelationsForHit(hit)
  async getRelationsForHit() {
    return {children: [], parents: [], siblings: []}
  }

}
