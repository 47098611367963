/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
/**
 * Viser link til datacvr.virk.dk for cvr.produktionsenhed resultater
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.CVR_enhedSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.CvrLinkHandler
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.CvrLinkHandler({more: false}))
 *
 * @example <caption>ES6:</caption>
 * import CvrLinkHandler from './searchers/detailhandlers/CvrLinkHandler.js'
 * dawaSearcher.addDetailHandlerDef(new CvrLinkHandler({more: false}))
 * @api
 **/
export default class CvrLinkHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   **/
  constructor(options= {}) {
    let defaultOptions = {
      buttonText: "CVR-info",
      buttonImage: icons.searchers.cvr,
      targets: [{source: "cvr", typeId: "produktionsenhed"}]
    }
    super(Object.assign(defaultOptions, options))
    this.handlerFunction = this.myHandler
  }

  async myHandler(result) {
    var items = []
    let url = "http://datacvr.virk.dk/data/visenhed?enhedstype=produktionsenhed&id=" + result.data.fields.pnr[0]
    items.push(
      { type: 'link',
        icon: this.more ? this.buttonImage : icons.details.link,
        link: url,
        linkTitle: "Se CVR-info på virk.dk"})
    return items
  }
}