/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from '../../resources/icons.js'
import DetailItemsList from "../../details/DetailItemsList.js"
/**
 * Viser info for Vedtagne lokalplaner, byggefelter, delområder, kommuneplanrammer og regionplaner
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:<a href='../examples/plansystemsearcher/'>Example</a></caption>
    plansystemsearcher:
      _type: Septima.Search.PlanSystemSearcher
      _options:
        minimumShowCount: 3
        targets:
          - vedtagetlokalplan
          - kommuneplanramme
          - kommuneplantillaeg
          - regionplan
          - byggefelt
          - delområde
        searchindexToken: septimaSEARCHDEMO-A7OLGHG2J4
      detailhandlers:
        - _type: "Septima.Search.PlanInfoProvider"
          _options:
            fields:
              lp: [plannr', 'plannavn', 'anvendelsegenerel', 'doklink', 'anvspec', 'datovedt', 'datoforsl']
              kpl: ['kommuneplan_anvendelse','kommuneplan_bebyggelse','kommuneplan_trafik_parkering','kommuneplan_zonebestemmelser','kommuneplan_miljoe','kommuneplan_andreforhold','anvendelsegenerel', 'plannr', 'anvspec', 'sforhold', 'notomranv', 'datoikraft', 'datoforsl', 'datovedt']
              kpt: ['kommuneplan_anvendelse','kommuneplan_bebyggelse','kommuneplan_trafik_parkering','kommuneplan_zonebestemmelser','kommuneplan_miljoe','kommuneplan_andreforhold','anvendelsegenerel', 'plannr', 'anvspec', 'sforhold', 'notomranv', 'datoikraft', 'datoforsl', 'datovedt','opdateret']
 * @example <caption> JS options: </caption>
 options = {
    fields: {
      lp: [plannr', 'plannavn', 'anvendelsegenerel', 'doklink', 'anvspec', 'datovedt', 'datoforsl']
      kpl: ['kommuneplan_anvendelse','kommuneplan_bebyggelse','kommuneplan_trafik_parkering','kommuneplan_zonebestemmelser','kommuneplan_miljoe','kommuneplan_andreforhold','anvendelsegenerel', 'plannr', 'anvspec', 'sforhold', 'notomranv', 'datoikraft', 'datoforsl', 'datovedt']
      kpt: ['kommuneplan_anvendelse','kommuneplan_bebyggelse','kommuneplan_trafik_parkering','kommuneplan_zonebestemmelser','kommuneplan_miljoe','kommuneplan_andreforhold','anvendelsegenerel', 'plannr', 'anvspec', 'sforhold', 'notomranv', 'datoikraft', 'datoforsl', 'datovedt','opdateret']
    }
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * planSearcher.addDetailHandlerDef(new Septima.Search.PlanInfoProvider(options))
 *
 * @example <caption>ES6:</caption>
 * import PlanInfoProvider from './searchers/detailhandlers/PlanInfoProvider.js'
 * planSearcher.addDetailHandlerDef(new PlanInfoProvider(options))
 * @api
 **/

export default class PlanInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields] Felter, der skal medtages i den viste info
   * @param {string[]} [options.fields.lp = ["anvendelsegenerel", "plannr","datoforsl","datovedt", "datoikraft", "byggefelter_result","delomraader_result"]] For lokalplaner
   * @param {string[]} [options.fields.lpf = ["anvendelsegenerel", "plannr", "datoforsl","datovedt", "datoikraft", "byggefelter_result","delomraader_result"]] For lokalplaner
   * @param {string[]} [options.fields.kpl = ["anvendelsegenerel", "plannr", "anvspec", "sforhold", "notomranv", "datoikraft", "datoforsl", "datovedt","kommuneplan_anvendelse","kommuneplan_bebyggelse","kommuneplan_trafik_parkering","kommuneplan_zonebestemmelser","kommuneplan_miljoe","kommuneplan_andreforhold"]] For kommuneplanrammer
   * @param {string[]} [options.fields.kpt = ["anvendelsegenerel", "plannr", "anvspec", "sforhold", "notomranv", "datoikraft", "datoforsl", "datovedt","kommuneplan_anvendelse","kommuneplan_bebyggelse","kommuneplan_trafik_parkering","kommuneplan_zonebestemmelser","kommuneplan_miljoe","kommuneplan_andreforhold","opdateret"]] For kommuneplantyllæg
   * @param {string[]} [options.fields.bf = ["delnr", "doklink", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]] For byggefelter
   * @param {string[]} [options.fields.do = ["delnr", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]] For delområder
   * @param {string[]} [options.fields.ko = ["objekt_id", "cvrname", "bruger_id", "navn1201","vaerd1201a","vaerd1201b,"""staar1201","sluaar1201", "datoforsl", "datovedt", "datoikraft"]] For kloakoplande
   * @param {string[]} [options.fields.kou = ["objekt_id", "cvrname", "bruger_id", "navn1201","vaerdi1206"]] For Mulighed for udtræden kloakoplande
   * @param {string[]} [options.fields.zs = ["planid", "kommunenavn", "zonestatus"]] FZonestatus

   * @param {string[]} [options.fields.rp = ["amt", "doklink"]] For regionplaner
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: ""
    }
    
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.handler

    //Default fields
    this.fields = {}
    this.fields.lp = ["anvendelsegenerel", "plannr", "datoforsl", "datovedt", "datoikraft", "byggefelter_result", "delomraader_result"]
    this.fields.lpf = ["anvendelsegenerel", "plannr", "datoforsl", "datovedt", "datoikraft"]
    this.fields.kpl = ["anvendelsegenerel", "plannr", "anvspec", "sforhold", "notomranv", "datoikraft", "datoforsl", "datovedt"]
    this.fields.kpt = ["anvendelsegenerel", "plannr", "anvspec", "sforhold", "notomranv", "datoikraft", "datoforsl", "datovedt"]
    this.fields.bf = ["delnr", "doklink", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]
    this.fields.do = ["delnr", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]
    this.fields.rp = ["amt", "doklink"]
    this.fields.ko = ["objekt_id", "cvrname", "bruger_id", "navn1201", "vaerd1201a", "vaerd1201b", "staar1201", "sluaar1201", "datoforsl", "datovedt", "datoikraft"]
    this.fields.kou = ["objekt_id", "navn1201", "vaerdi1206"]
    this.fields.zs = ["planid", "kommunenavn", "zonestatus"]

    //Read fields from options
    if (typeof options !== 'undefined' && typeof options.fields !== 'undefined') {
      if (typeof options.fields.lp !== 'undefined')
        this.fields.lp = options.fields.lp
      if (typeof options.fields.lpf !== 'undefined')
        this.fields.lf = options.fields.lpf
      if (typeof options.fields.kpl !== 'undefined')
        this.fields.kpl = options.fields.kpl
      if (typeof options.fields.kpt !== 'undefined')
        this.fields.kpt = options.fields.kpt
      if (typeof options.fields.bf !== 'undefined')
        this.fields.bf = options.fields.bf
      if (typeof options.fields.do !== 'undefined')
        this.fields.do = options.fields.do
      if (typeof options.fields.rp !== 'undefined')
        this.fields.rp = options.fields.rp
      if (typeof options.fields.ko !== 'undefined')
        this.fields.ko = options.fields.ko
      if (typeof options.fields.kou !== 'undefined')
        this.fields.ko = options.fields.kou
      if (typeof options.fields.zs !== 'undefined')
        this.fields.zs = options.fields.zs
    }
  }

  getbuttonText(result) {
    if (this.buttonText != '') {
      return this.buttonText
    } else {
      let tabtext = 'Om planen'
      if (result.typeId === "vedtagetlokalplan")
        tabtext = 'Om lokalplanen'
      else if (result.typeId === "lokalplanforslag")
        tabtext = 'Om lokalplanforslaget'
      else if (result.typeId === "kommuneplanramme")
        tabtext = 'Om kommuneplanrammen'
      else if (result.typeId === "kommuneplantillaeg")
        tabtext = 'Om kommuneplantillægget'
      else if (result.typeId === "regionplan")
        tabtext = 'Om regionplanen'
      else if (result.typeId === "byggefelt")
        tabtext = 'Om byggefeltet'
      else if (result.typeId === "delområde")
        tabtext = 'Om delområdet'
      else if (result.typeId === "vedtagetkloakopland")
        tabtext = 'Om kloakoplandet'
      else if (result.typeId === "mulighedforudtraedenkloakopland")
        tabtext = 'Om kloakoplandet'
      else if (result.typeId === "zonestatus")
        tabtext = 'Om zonen'
      return tabtext
    }
  }

  async handler(result) {
    let anvspecvals = []

    if (result.typeId === "lokalplanforslag" || result.typeId === "vedtagetlokalplan" || result.typeId === "kommuneplanramme" || result.typeId === "kommuneplantillaeg" || result.typeId === "byggefelt" || result.typeId === "delområde")
      Object.entries(result.data.properties).map(obj => {
        const key = obj[0]
        const value = obj[1]
        if (key.startsWith('anvspec') && value)
          anvspecvals.push(result.searcher.getAnvendelsesTekstFraKode(value))
      })

    let items = []
    if (result.typeId === "vedtagetlokalplan")
      for (let field of this.fields.lp) {
        let item = await this.createLpItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "lokalplanforslag")
      for (let field of this.fields.lp) {
        let item = await this.createLpItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)
      } else if (result.typeId === "kommuneplanramme")
      for (let field of this.fields.kpl) {
        let item = this.createKplItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "kommuneplantillaeg")
      for (let field of this.fields.kpt) {
        let item = this.createKplItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "byggefelt")
      for (let field of this.fields.bf) {
        let item = await this.createBfItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "delområde")
      for (let field of this.fields.do) {
        let item = await this.createDoItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)

      } else if (result.typeId === "regionplan")
      for (let field of this.fields.rp) {
        let item = await this.createRpItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      } else if (result.typeId === "zonestatus")
      for (let field of this.fields.zs) {
        let item = await this.createZsItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      } else if (result.typeId === "vedtagetkloakopland")
      for (let field of this.fields.ko) {
        let item = await this.createKoItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }
    else if (result.typeId === "mulighedforudtraedenkloakopland")
      for (let field of this.fields.kou) {
        let item = await this.createKouItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }

    return items

  }
  createKouItem(result, field) {
    let props = result.data.properties
    if (field === 'objekt_id')
      return {
        type: 'labelvalue',
        label: 'PlanID',
        value: props.objekt_id
      }
    if (field === 'navn1201')
      return {
        type: 'labelvalue',
        label: 'Navn',
        value: props.navn1206
      }
    else if (field === 'cvrname')
      return {
        type: 'labelvalue',
        label: 'CVR navn',
        value: props.cvrname
      }
    else if (field === 'bruger_id')
      return {
        type: 'labelvalue',
        label: 'Bruger',
        value: props.bruger_id
      }
    else if (field === 'vaerdi1206')
      return {
        type: 'labelvalue',
        label: 'Mulighed for udtræden',
        value: props.vaerdi1206
      }
    else if (field === 'staar1201')
      return {
        type: 'labelvalue',
        label: 'Årstal for planlægt ændring af status',
        value: props.staar1201 || 'Ikke angivet'
      }
    else if (field === 'sluaar1201')
      return {
        type: 'labelvalue',
        label: 'Årstal for planlagt ikrafttrædelse',
        value: props.sluaar1201 || 'Ikke angivet'
      }
    else if (field === 'datoforsl') {
      return this.writeDateItem('Dato forslag', props.datoforsl)
    } else if (field === 'datovedt') {
      return this.writeDateItem('Dato vedtaget', props.datovedt)
    } else if (field === 'datoikraft') {
      return this.writeDateItem('Dato i kraft', props.datoikraft)
    }
    return
  }
  createKoItem(result, field) {
    let props = result.data.properties
    if (field === 'objekt_id')
      return {
        type: 'labelvalue',
        label: 'PlanID',
        value: props.objekt_id
      }
    if (field === 'navn1201')
      return {
        type: 'labelvalue',
        label: 'Områdenavn',
        value: props.navn1201
      }
    else if (field === 'cvrname')
      return {
        type: 'labelvalue',
        label: 'CVR navn',
        value: props.cvrname
      }
    else if (field === 'bruger_id')
      return {
        type: 'labelvalue',
        label: 'Bruger',
        value: props.bruger_id
      }
    else if (field === 'vaerd1201a')
      return {
        type: 'labelvalue',
        label: 'Kloaktype',
        value: props.vaerd1201a
      }
    else if (field === 'vaerd1201b')
      return {
        type: 'labelvalue',
        label: 'Planlagt kloaktype',
        value: props.vaerd1201b
      }
    else if (field === 'staar1201')
      return {
        type: 'labelvalue',
        label: 'Årstal for planlægt ændring af status',
        value: props.staar1201 || 'Ikke angivet'
      }
    else if (field === 'sluaar1201')
      return {
        type: 'labelvalue',
        label: 'Årstal for planlagt ikrafttrædelse',
        value: props.sluaar1201 || 'Ikke angivet'
      }
    else if (field === 'datoforsl') {
      return this.writeDateItem('Dato forslag', props.datoforsl)
    } else if (field === 'datovedt') {
      return this.writeDateItem('Dato vedtaget', props.datovedt)
    } else if (field === 'datoikraft') {
      return this.writeDateItem('Dato i kraft', props.datoikraft)
    }
    return
  }

  writeDateItem(label, dateItem) {
    let value = 'Ikke angivet'
    let valueformat = "string"
    if (this.hasvalue(dateItem)) {
      dateItem += ""
      value = dateItem.substr(0, 4) + "-" + dateItem.substr(4, 2) + "-" + dateItem.substr(6, 2)
      valueformat = "iso-date"
    }
    return {
      type: 'labelvalue',
      label: label,
      value: value,
      valueformat: valueformat
    }
  }

  hasvalue(item) {
    if (typeof item !== 'undefined') {
      if (item !== '' && item !== null)
        return true
    }
    return false
  }
  
  createZsItem(result, field) {
    let props = result.data.properties
    if (field === 'planid')
      return {
        type: 'labelvalue',
        label: 'Planid',
        value: props.planid
      }
    else if (field === 'kommunenavn')
      return {
        type: 'labelvalue',
        label: 'Kommune',
        value: props.kommunenavn,

      }
    else if (field === 'zonestatus')
      return {
        type: 'labelvalue',
        label: 'Zonestatus',
        value: props.zonestatus

      }
    return
  }
  createRpItem(result, field) {
    let props = result.data.properties
    if (field === 'amt')
      return {
        type: 'labelvalue',
        label: 'Amt',
        value: props.amtnavn.replace('Ã¸', 'ø')
      }
    else if (field === 'doklink')
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.dokumentna,
        target: '_blank',
        icon: icons.details.link,
      }
    else if (field === 'dokumentnavn')
      return {
        type: 'labelvalue',
        label: 'Dokumentnavn',
        value: props.dokumentna

      }
    return
  }

  async createDoItem(result, field, anvspec) {

    let props = result.data.properties

    if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        target: '_blank',
        icon: icons.details.link,

      }
    } else if (field === 'lp_plannavn') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan',
        value: props.lp_plannavn

      }
    } else if (field === 'lokplan_id') {
      let planResult = await result.searcher.get(props.lokplan_id, 'vedtagetlokalplan')
      return {
        type: 'result',
        result: planResult
      }
    } else if (field === 'lp_plannr') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan nr',
        value: props.lp_plannr
      }
    } else if (field === 'delnr') {
      return {
        type: 'labelvalue',
        label: 'Delnr',
        value: props.delnr

      }
    } else if (field === 'anvspec') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'sforhold') {
      return {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold
      }
    } else if (field === 'notomranv') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }

  async createBfItem(result, field, anvspec) {

    let props = result.data.properties

    if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        icon: icons.details.link,

      }
    } else if (field === 'lp_plannavn') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan',
        value: props.lp_plannavn

      }
    } else if (field === 'lokplan_id') {
      let planResult = await result.searcher.get(props.lokplan_id, 'vedtagetlokalplan')
      return {
        type: 'result',
        result: planResult
      }
    } else if (field === 'lp_plannr') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan nr',
        value: props.lp_plannr
      }
    } else if (field === 'delnr') {
      return {
        type: 'labelvalue',
        label: 'Delnr',
        value: props.delnr

      }
    } else if (field === 'anvspec') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'sforhold') {
      return {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold
      }
    } else if (field === 'notomranv') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }
  createAnvendelseKpl(properties) {
    let p = properties
    let textarea = ''
    if (typeof p.notomranv !== 'undefined' && p.notomranv !== null && p.notomranv !== '') {
      if (typeof p.notomranv !== 'undefined' && p.notomranv !== null && p.notomranv !== '') {
        textarea += p.notomranv
      }
      if (typeof p.notanvgen !== 'undefined' && p.notanvgen !== null && p.notanvgen !== '') {
        textarea += p.notanvgen
      }
    } else
      return

    return {
      type: 'labelvalue',
      label: 'Anvendelse',
      value: textarea
    }

  }
  createBebyggelseKpl(properties) {
    let p = properties
    let textarea = ''
    if (typeof p.notbebygom !== 'undefined' && p.notbebygom !== null && p.notbebygom !== '') {
      textarea += p.notbebygom
      textarea += '\n'
    }
    if (typeof p.bebygpct !== 'undefined' && p.bebygpct !== null && p.bebygpct !== 0) {
      let bp = p.bebygpct.toString().replace('.', ',')
      let bpa = {
        '1': 'området som helhed',
        '2': 'den enkelte ejendom',
        '3': 'den enkelte grund',
        '4': 'det enkelte jordstykke'
      }
      let bebygpctaf = (p.bebygpctaf !== null ? ' for ' + bpa[p.bebygpctaf] : '')
      textarea += `Bebyggelsesprocenten ${bebygpctaf} må ikke overstige ${bp}.`
      textarea += '\n'
    }
    if (typeof p.maxbygnhjd !== 'undefined' && p.maxbygnhjd !== null && p.maxbygnhjd !== 0) {
      let mh = p.maxbygnhjd.toString().replace('.', ',')
      textarea += `Bebyggelseshøjden må ikke overstige ${mh} m.`
      textarea += '\n'
    }
    if (typeof p.maxetager !== 'undefined' && p.maxetager === 1) {
      textarea += `Bebyggelsen må ikke opføres i mere end 1 etage.`
      textarea += '\n'
    }
    if (typeof p.maxetager !== 'undefined' && p.maxetager > 1) {
      let me = p.maxetager.toString().replace('.', ',')
      textarea += `Bebyggelsen må ikke opføres i mere end ${me} etager.`
      textarea += '\n'
    }
    if (typeof p.m3_m2 !== 'undefined' && p.m3_m2 !== null && p.m3_m2 !== 0) {
      textarea += `Bygningernes rumfang må ikke overstige ${p.m3_m2} m3 pr. m2 grundareal. `
      textarea += '\n'
    } else
      return
    return {
      type: 'labelvalue',
      label: 'Bebyggelse',
      value: textarea
    }

  }
  createTrafikParkeringKpl(properties) {
    let p = properties
    let textarea = ''

    if (typeof p.notinfras !== 'undefined' && p.notinfras !== null && p.notinfras !== '') {
      textarea += p.notinfras
      textarea += '\n'
    } else
      return
    return {
      type: 'labelvalue',
      label: 'Trafik og Parkering',
      value: textarea
    }

  }
  createZoneBestemmelserKpl(properties) {
    let p = properties
    let textarea = ''

    if (typeof p.notzone !== 'undefined' && p.notzone !== null && p.notzone !== '') {
      textarea += p.notzone
      textarea += '\n'
    } else
      return
    return {
      type: 'labelvalue',
      label: 'Zonebestemmelser',
      value: textarea
    }

  }
  createMiljoeKpl(properties) {
    let p = properties
    let textarea = ''

    if ((typeof p.notmiljo !== 'undefined' && p.notmiljo !== null && p.notmiljo !== '') || (typeof p.minmiljo !== 'undefined' && p.minmiljo !== null && p.minmiljo !== 0) || (typeof p.maxmiljo !== 'undefined' && p.maxmiljo !== null && p.maxmiljo !== 0)) {
      let miljotester = [
        '',
        ', hvilket svarer til virksomheder og anlæg, der ikke giver anledning til nævneværdig påvirkning af omgivelserne. Miljømæssig afstand til boliger ikke nødvendig. ',
        ', hvilket svarer til virksomheder og anlæg med \'ubetydelig\' påvirkning af de nærmeste omgivelser. Afstand til boliger bør være ca. 20 m. ',
        ', hvilket svarer til virksomheder og anlæg, der fra udendørsaktiviteter (herunder transport) giver anledning til mindre påvirkning af omgivelserne: \'Uvæsentlig forurening\'. Afstand til boliger bør være ca. 50 m. ',
        ', hvilket svarer til virksomheder og anlæg, der kan medføre \'mindre væsentlig forurening\'. Afstand til boliger bør være ca. 100 m. ',
        ', hvilket svarer til virksomheder og anlæg, der kan medføre \'væsentlig forurening\'. Afstand til boliger bør være ca. 150 m. ',
        ', hvilket svarer til virksomheder og anlæg,der kan medføre \'betydelig forurening\'. Afstand til boliger bør være ca. 300m. ',
        ', hvilket svarer til virksomheder og anlæg, der kan give anledning til \'omfattende forurening\' og derfor stiller særlige beliggenhedskrav. Kan f.eks. omfatte medicinal- og pesticidproduktion, kraftværker og svinefabrikker. Afstand til boliger bør være mindst 500 m. '
      ]
      if (typeof p.minmiljo !== 'undefined' && p.minmiljo !== null && p.minmiljo !== 0) {
        textarea += `Virksomheder og anlæg skal minimum have miljøklasse: ${p.minmiljo + miljotester[p.minmiljo]}`
        textarea += '\n'
      }
      if (typeof p.maxmiljo !== 'undefined' && p.maxmiljo !== null && p.maxmiljo !== 0) {
        textarea += `Virksomheder og anlæg må maksimum have miljøklasse: ${p.maxmiljo + miljotester[p.maxmiljo]}`
        textarea += '\n'
      }
      if (typeof p.notmiljo !== 'undefined' && p.notmiljo !== null && p.notmiljo !== '') {
        textarea += p.notmiljo
        textarea += '\n'
      }
    } else
      return

    return {
      type: 'labelvalue',
      label: 'Miljø',
      value: textarea
    }

  }
  createAndreforholdKpl(properties) {
    let p = properties
    let textarea = ''

    if ((typeof p.notat !== 'undefined' && p.notat !== null && p.notat !== '') || (typeof p.notlokalp !== 'undefined' && p.notlokalp !== null && p.notlokalp !== '') || (typeof p.notophold !== 'undefined' && p.notophold !== null && p.notophold !== '')) {
      if (typeof p.notlokalp !== 'undefined' && p.notlokalp !== null && p.notlokalp !== '') {
        textarea += p.notlokalp
        textarea += '\n'
      }
      if (typeof p.notat !== 'undefined' && p.notat !== null && p.notat !== '') {
        textarea += p.notat
        textarea += '\n'
      }
      if (typeof p.notophold !== 'undefined' && p.notophold !== null && p.notophold !== '') {
        textarea += p.notophold
        textarea += '\n'
      }
    } else
      return
    return {
      type: 'labelvalue',
      label: 'Andre forhold',
      value: textarea
    }

  }
  opdateretKpt(properties) {
    let p = properties
    let dato = (p.datoikraft).toString()
    let y = dato.substr(0, 4)
    let m = dato.substr(4, 2)
    let d = dato.substr(6, 2)
    return {
      type: 'labelvalue',
      label: 'Opdateret kommuneplanramme',
      value: `Kommuneplanramme er opdateret og ændret i henhold til kommuneplantillæg gældende fra ${d}.${m}.${y}.`
    }
  }
  createKplItem(result, field, anvspec) {

    let props = result.data.properties

    if (field === 'opdateret' && result.typeId === 'kommuneplantillaeg')
      return this.opdateretKpt(props)

    if (field === 'kommuneplan_anvendelse') {
      return this.createAnvendelseKpl(props)
    }
    if (field === 'kommuneplan_bebyggelse') {
      return this.createBebyggelseKpl(props)
    }
    if (field === 'kommuneplan_trafik_parkering') {
      return this.createTrafikParkeringKpl(props)
    }
    if (field === 'kommuneplan_zonebestemmelser') {
      return this.createZoneBestemmelserKpl(props)
    }
    if (field === 'kommuneplan_miljoe') {
      return this.createMiljoeKpl(props)
    }
    if (field === 'kommuneplan_andreforhold') {
      return this.createAndreforholdKpl(props)
    }
    if (field === 'anvendelsegenerel') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse generel',
        value: props.anvendelsegenerel || 'Anvendelse ikke reguleret'
      }
    } else if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        target: '_blank',
        icon: icons.details.link,

      }
    } else if (field === 'plannavn') {
      return {
        type: 'labelvalue',
        label: 'Plannavn',
        value: props.plannavn

      }
    } else if (field === 'anvspec') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'plannr') {
      return {
        type: 'labelvalue',
        label: 'Plannr',
        value: props.plannr || 'ingen plannr'
      }
    } else if (field === 'sforhold') {
      return {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold || 'Ingen særlige forhold angivet'
      }
    } else if (field === 'notomranv') {
      return {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv || 'Ingen rammetekst angivet'
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }
  async createLpItem(result, field, anvspec) {

    let props = result.data.properties
    if (field === 'anvendelsegenerel') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse generel',
        value: props.anvendelsegenerel || 'Anvendelse ikke reguleret'
      }
    } else if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        target: '_blank',
        icon: icons.details.link,

      }
    } else if (field === 'plannavn') {
      return {
        type: 'labelvalue',
        label: 'Plannavn',
        value: props.plannavn

      }
    } else if (field === 'anvspec') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'byggefelter_result') {
      let queryResult = await result.searcher.getByggefeltByPlanId(result.id)
      let results = queryResult.getResults()
      if (results.length > 0) {
        let header = results.length === 1 ? "Byggefelt" : "Byggefelter"
        let detailItemsList = new DetailItemsList({
          itemType: "result",
          header: header,
          isHomogenous: true,
          name: "byggefelter_liste"
        })
        for (let result of results)
          detailItemsList.append({
            type: "result",
            result: result
          })
        return detailItemsList.asItem()
      } else {
        let detailItemsList = new DetailItemsList({
          itemType: "labelvalue",
          header: 'Byggefelter',
          isHomogenous: true,
          name: "byggefelter_ingen"
        })
        detailItemsList.append({
          type: 'labelvalue',
          value: "Ingen byggefelter fundet"
        })
        return detailItemsList.asItem()
      }
    } else if (field === 'delomraader_result') {
      let queryResult = await result.searcher.getDelomraadeByPlanId(result.id)
      let results = queryResult.getResults()
      if (results.length > 0) {
        let header = results.length === 1 ? "Delområde" : "Delområder"
        let detailItemsList = new DetailItemsList({
          itemType: "result",
          header: header,
          isHomogenous: true,
          name: "delomraader_liste"
        })
        for (let result of results)
          detailItemsList.append({
            type: "result",
            result: result
          })
        return detailItemsList.asItem()
      } else {
        let detailItemsList = new DetailItemsList({
          itemType: "labelvalue",
          header: 'Delområder',
          isHomogenous: true,
          name: "delomraader_ingen"
        })
        detailItemsList.append({
          type: 'labelvalue',
          value: "Ingen delområder fundet"
        })
        return detailItemsList.asItem()
      }
    } else if (field === 'plannr') {
      return {
        type: 'labelvalue',
        label: 'Plannr',
        value: props.plannr || 'ingen plannr'
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return {
        type: 'labelvalue',
        label: 'Dato forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }

}