import {getWKTParser} from "../../util/getWKTParser.js"

export default class GeometrySearchProvider {
  //This search provider returns a result if the query represents a coordinate.
  // The provider is projection agnostic:
  //  A result is returned if the query can be parsed as two numbers OR wkt AND
  //  all coordinates are within allowed boundaries

  constructor(options = {}) {
    //options optional
    //options.boundaries (optional)
    // Boundaries object: Coordinates are validated
    // NULL: Coordinates are NOT validated
    if (typeof options.boundaries !== 'undefined') {
      this.boundaries = options.boundaries
    } else {
      //Default allowed boundaries (Extent of Denmark in epsg:25832)
      let defaultBoundaries = {
        firstLower: 430000,
        firstUpper: 950000,
        secondLower: 6000000,
        secondUpper: 6500000,
        description: '[type] i [projection] i Danmark', //Description is optional
        projection: 'epsg:25832'
      }
      this.boundaries = defaultBoundaries
    }
    
    let defaultLang = {
      point: "Punkt",
      linestring: "Linie",
      polygon: "Flade"
    }
    
    if (typeof options.lang !== 'undefined') 
      this.lang = Object.assign(defaultLang, options.lang)
    else 
      this.lang = defaultLang
  }

  query(query) {
    let response = {status: 'ok', hits: 0, results: []}
    if (query.indexOf("point") === 0 || query.indexOf("linestring") === 0 || query.indexOf("polygon") === 0) {
      let wktParser = getWKTParser()
      try {
        let geometry = wktParser.parse(query, '25832')
        if ( geometry) {
          if (this.checkWithinBoundaries(geometry)) {
            response.results.push(this.createGeometryResult(query, geometry))
            response.hits = 1
          }
        }
      } catch (e) {
        return response
      }
    } else {
      let coordinateArray = this.createValidCoordinateArray(query)
      if (coordinateArray !== null) {
        response.results.push(this.createCoordinateResult(query, coordinateArray))
        response.hits = 1
      }
    }
    return response
  }

  checkWithinBoundaries(geometry) {
    if (this.boundaries !== null) {
      if (geometry.type === "Point") {
        let position = geometry.coordinates
        return (
          position[0] > this.boundaries.firstLower &&
          position[0] < this.boundaries.firstUpper &&
          position[1] > this.boundaries.secondLower &&
          position[1] < this.boundaries.secondUpper)
      } else if (geometry.type === "Linestring") {
        for (let position of geometry.coordinates) {
          if (
            position[0] < this.boundaries.firstLower &&
            position[0] > this.boundaries.firstUpper &&
            position[1] < this.boundaries.secondLower &&
            position[1] > this.boundaries.secondUpper)
            return false
        }
      } else if (geometry.type === "Polygon") {
        for (let position of geometry.coordinates[0]) {
          if (
            position[0] < this.boundaries.firstLower &&
            position[0] > this.boundaries.firstUpper &&
            position[1] < this.boundaries.secondLower &&
            position[1] > this.boundaries.secondUpper)
            return false
        }
      }
    }
    return true
  }
  createGeometryResult(query, geometry) {
    let result = {
      title: this.lang[geometry.type.toLowerCase()],
      geometry: geometry,
      id: query
    }
    if (this.boundaries !== null && this.boundaries.description) {
      result.description = this.boundaries.description.replace("[type]", this.lang['point'])
      if (this.boundaries !== null && this.boundaries.projection)
        result.description = result.description.replace("[projection]", this.boundaries.projection)
    }

    return result
    
  }

  createValidCoordinateArray(string) {
    let parts = string.split(' ')
    if (parts.length === 1) 
      parts = string.split(',')
    
    if (parts.length === 2) {
      let firstPart = parseFloat(parts[0])
      let secondPart = parseFloat(parts[1])
      if (!isNaN(firstPart) && !isNaN(secondPart))
        if (this.boundaries === null)
          return [firstPart, secondPart]
        else if (
          firstPart  > this.boundaries.firstLower &&
          firstPart  < this.boundaries.firstUpper &&
          secondPart > this.boundaries.secondLower &&
          secondPart < this.boundaries.secondUpper)
          return [firstPart, secondPart]
    }
    return null
  }

  get_org(coordinates) {
    let coordinateArray = this.createValidCoordinateArray(coordinates)
    return this.createCoordinateResult(coordinates, coordinateArray)
  }
  
  get(id) {
    let queryResponse = this.query(id.toLowerCase())
    if (queryResponse && queryResponse.results.length > 0)
      return queryResponse.results[0]
    else
      return null
  }
  
  createCoordinateResult(title, coordinateArray) {
    let result = {
      title: this.lang['point'],
      geometry: {
        'type': 'Point',
        'coordinates': coordinateArray,
      },
      id: coordinateArray.join(",")
    }
    if (this.boundaries !== null && this.boundaries.projection)
      result.geometry.crs = {
        "type": "name",
        "properties": {
          "name": this.boundaries.projection
        }
      }
    if (this.boundaries !== null && this.boundaries.description) {
      result.description = this.boundaries.description.replace("[type]", this.lang['point'])
      if (this.boundaries !== null && this.boundaries.projection)
        result.description = result.description.replace("[projection]", this.boundaries.projection)
    }
    
    return result
  }

}